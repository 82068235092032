import type { FC } from "react";
import { StaticImage } from "gatsby-plugin-image";
import nineGag from "images/logos/9gag.svg";
import anyClip from "images/logos/any-clip.svg";
import apartmentTherapy from "images/logos/apartment-therapy.svg";
import blogicMedia from "images/logos/blogic-media.svg";
import britannica from "images/logos/britannica.svg";
import connatix from "images/logos/connatix.svg";
import futbolSites from "images/logos/futbol-sites.svg";
import gradient from "images/logos/gradient.svg";
import gumgum from "images/logos/gumgum.svg";
import heartAutos from "images/logos/heart-autos.svg";
import investingChannel from "images/logos/investing-channel.svg";
import kronenZeitung from "images/logos/kronen-zeitung.svg";
import kurier from "images/logos/kurier.svg";
import leafGroup from "images/logos/leaf-group.svg";
import minuteMedia from "images/logos/minute-media.svg";
import netpoint from "images/logos/netpoint.svg";
import pubPlus from "images/logos/pub-plus.svg";
import reverso from "images/logos/reverso.svg";
import shinez from "images/logos/shinez.svg";
import step from "images/logos/step.svg";
import WHE from "images/logos/WHE.svg";
import worldOfGood from "images/logos/world-of-good.svg";
import "./clients.scss";

const Clients: FC<{
    title?: string;
    topmargin?: boolean;
    bg?: string;
    showLink?: boolean;
    linkText?: string;
    linkTo?: string;
    linkColor?: string;
}> = ({ title, topmargin, bg, showLink, linkText, linkTo, linkColor }) => {
    return (
        //top margin for new and old game for yield manager page
        <div className={`component-wrapper ${topmargin ? "mt-180" : ""} change-margin-to-padding ${bg} pt-5`}>
            <img src={gradient} className={`gradient right`} style={{ top: "-250px" }} alt="gradient" />
            <div className="clients">
                <section className="enable-animation marquee-clients">
                    {title ? <h2 className="text-center mb-5">{title}</h2> : ""}
                    <div className="marquee ">
                        <ul className="marquee__group">
                            <li>
                                <img src={nineGag} alt="" />
                            </li>
                            <li>
                                <img src={apartmentTherapy} alt="" />
                            </li>
                            <li>
                                <img src={blogicMedia} alt="" />
                            </li>
                            <li>
                                <img src={britannica} alt="" />
                            </li>
                            <li>
                                <img src={connatix} alt="" />
                            </li>
                            <li>
                                <img src={step} alt="" />
                            </li>
                            <li>
                                <img src={futbolSites} alt="" />
                            </li>
                            <li
                                style={{
                                    marginBottom: "15px",
                                }}
                            >
                                <img src={gumgum} alt="" />
                            </li>
                            <li>
                                <img src={heartAutos} alt="" />
                            </li>
                            <li>
                                <img src={minuteMedia} alt="" />
                            </li>
                            <li>
                                <img src={anyClip} alt="" />
                            </li>
                            <li>
                                <img src={netpoint} alt="" />
                            </li>
                            <li>
                                <img
                                    style={{
                                        marginBottom: "17px",
                                    }}
                                    src={shinez}
                                    alt=""
                                />
                            </li>
                            <li>
                                <img src={WHE} alt="" />
                            </li>
                            <li>
                                <img src={kronenZeitung} alt="" />
                            </li>
                            <li>
                                <img src={kurier} alt="" />
                            </li>
                            <li>
                                <img src={investingChannel} alt="" />
                            </li>
                            <li>
                                <img src={reverso} alt="" />
                            </li>
                            <li>
                                <img src={pubPlus} alt="" />
                            </li>
                            <li>
                                <img src={leafGroup} alt="" />
                            </li>
                            <li>
                                <img src={worldOfGood} alt="" />
                            </li>
                        </ul>
                        <ul className="marquee__group aria_hidden" aria-hidden="true">
                            <li>
                                <img src={nineGag} alt="" />
                            </li>
                            <li>
                                <img src={apartmentTherapy} alt="" />
                            </li>
                            <li>
                                <StaticImage src="images/blogic.png" alt="" />
                            </li>
                            <li>
                                <img src={britannica} alt="" />
                            </li>
                            <li>
                                <img src={connatix} alt="" />
                            </li>
                            <li>
                                <img src={step} alt="" />
                            </li>
                            <li>
                                <img src={futbolSites} alt="" />
                            </li>
                            <li
                                style={{
                                    marginBottom: "15px",
                                }}
                            >
                                <img src={gumgum} alt="" />
                            </li>
                            <li>
                                <img src={heartAutos} alt="" />
                            </li>
                            <li>
                                <img src={minuteMedia} alt="" />
                            </li>
                            <li>
                                <img src={anyClip} alt="" />
                            </li>
                            <li>
                                <img src={netpoint} alt="" />
                            </li>
                            <li>
                                <img
                                    style={{
                                        marginBottom: "17px",
                                    }}
                                    src={shinez}
                                    alt=""
                                />
                            </li>
                            <li>
                                <img src={WHE} alt="" />
                            </li>
                            <li>
                                <img src={kronenZeitung} alt="" />
                            </li>
                            <li>
                                <img src={kurier} alt="" />
                            </li>
                            <li>
                                <img src={investingChannel} alt="" />
                            </li>
                            <li>
                                <img src={reverso} alt="" />
                            </li>
                            <li>
                                <img src={pubPlus} alt="" />
                            </li>
                            <li>
                                <img src={leafGroup} alt="" />
                            </li>
                            <li>
                                <img src={worldOfGood} alt="" />
                            </li>
                        </ul>
                    </div>
                </section>
                {showLink && linkColor === "green" && (
                    <a href={linkTo} className="link-wrapper">
                        <span className="link-text">{linkText}</span>
                        <StaticImage src="images/logos/arrow.svg" alt="arrow" />
                    </a>
                )}
                {showLink && linkColor === "yellow" && (
                    <a href={linkTo} className="link-wrapper">
                        <span className="link-text-yellow">{linkText}</span>
                        <StaticImage src="images/logos/yellow-arrow.svg" alt="arrow" />
                    </a>
                )}
            </div>
        </div>
    );
};

export default Clients;
