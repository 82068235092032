import { Builder } from "@builder.io/react";
import nineGag from "images/logos/9gag.svg";
import anyClip from "images/logos/any-clip.svg";
import apartmentTherapy from "images/logos/apartment-therapy.svg";
import blogicMedia from "images/logos/blogic-media.svg";
import britannica from "images/logos/britannica.svg";
import connatix from "images/logos/connatix.svg";
import futbolSites from "images/logos/futbol-sites.svg";
import gumgum from "images/logos/gumgum.svg";
import heartAutos from "images/logos/heart-autos.svg";
import investingChannel from "images/logos/investing-channel.svg";
import kronenZeitung from "images/logos/kronen-zeitung.svg";
import kurier from "images/logos/kurier.svg";
import leafGroup from "images/logos/leaf-group.svg";
import minuteMedia from "images/logos/minute-media.svg";
import netpoint from "images/logos/netpoint.svg";
import pubPlus from "images/logos/pub-plus.svg";
import reverso from "images/logos/reverso.svg";
import shinez from "images/logos/shinez.svg";
import step from "images/logos/step.svg";
import WHE from "images/logos/WHE.svg";
import worldOfGood from "images/logos/world-of-good.svg";
import { toMutable } from "types/common";
import "./expanded-clients.scss";
import rightArrow from "./images/right-arrow.svg";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "A next generation of data-driven teams",
    },
    {
        name: "linkText",
        type: "text",
        defaultValue: "See all clients",
    },
    {
        name: "linkTo",
        type: "string",
        defaultValue: "/clients",
    },
] as const;
const ExpandedClients: BFC<typeof inputs> = ({ title, linkText, linkTo }) => {
    return (
        <div className="component-wrapper">
            <div className="container">
                <div className="expanded-clients">
                    <section className="enable-animation marquee-clients">
                        {title ? <h2 className="text-center mb-5">{title}</h2> : ""}
                        <div className="marquee ">
                            <ul className="marquee__group">
                                <li>
                                    <img src={nineGag} alt="" />
                                </li>
                                <li>
                                    <img src={apartmentTherapy} alt="" />
                                </li>
                                <li>
                                    <img src={blogicMedia} alt="" />
                                </li>
                                <li>
                                    <img src={britannica} alt="" />
                                </li>
                                <li>
                                    <img src={connatix} alt="" />
                                </li>
                                <li>
                                    <img src={step} alt="" />
                                </li>
                                <li>
                                    <img src={futbolSites} alt="" />
                                </li>
                                <li>
                                    <img src={gumgum} alt="" />
                                </li>
                                <li>
                                    <img src={heartAutos} alt="" />
                                </li>
                                <li>
                                    <img src={minuteMedia} alt="" />
                                </li>
                                <li>
                                    <img src={anyClip} alt="" />
                                </li>
                                <li>
                                    <img src={netpoint} alt="" />
                                </li>
                                <li>
                                    <img src={shinez} alt="" />
                                </li>
                                <li>
                                    <img src={WHE} alt="" />
                                </li>
                                <li>
                                    <img src={kronenZeitung} alt="" />
                                </li>
                                <li>
                                    <img src={kurier} alt="" />
                                </li>
                                <li>
                                    <img src={investingChannel} alt="" />
                                </li>
                                <li>
                                    <img src={reverso} alt="" />
                                </li>
                                <li>
                                    <img src={pubPlus} alt="" />
                                </li>
                                <li>
                                    <img src={leafGroup} alt="" />
                                </li>
                                <li>
                                    <img src={worldOfGood} alt="" />
                                </li>
                            </ul>
                        </div>
                    </section>

                    {linkText ? (
                        <a href={linkTo} className="link-wrapper">
                            <span className="link-text">{linkText}</span>
                            <img src={rightArrow} alt="" />
                        </a>
                    ) : null}
                </div>
            </div>
        </div>
    );
};
Builder.registerComponent(ExpandedClients, {
    name: "ExpandedClients",
    inputs: toMutable(inputs),
});
