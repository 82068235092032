import {
    adRevenueReportFilterIds,
    dimensions,
    discrepancyRevenueReportFilterIds,
    financialReportFilterIds,
    type IDimension,
    industryReportFiltersIds,
    requestReductionClientV2FilterIds,
    requestReductionServerLatencyFilterIds,
    requestReductionServerSamplesPredictFilterIds,
    requestReductionServerSamplesProfileFilterIds,
    siteReportFilterIds,
    spentAdsetFilterIds,
    spentAdsFilterIds,
    spentBySectionReportFilterIds,
    spentCampaignFilterIds,
    yieldFilterIds,
} from "./_constants";
import type { DimensionKeys } from "./dimension-keys.constants";
import { AllBasePlacementKeyTypeCodec } from "./placement.constants";

export const AllReportPlacementKeyTypeInputCodec = [
    ...AllBasePlacementKeyTypeCodec,
    "yield_or_prebid_or_web",
    "__no_placement__",
] as const;
export type ReportPlacementKeyTypeInput = (typeof AllReportPlacementKeyTypeInputCodec)[number];
export type BasePlacementKeyType = (typeof AllBasePlacementKeyTypeCodec)[number];

export const ReportPlacementTitlesLookup: {
    [key in BasePlacementKeyType | "yield" | "prebid"]: string;
} = {
    yield: "Yield Analytics",
    flooring: "Yield Analytics",
    prebid: "Prebid Analytics",
    buy_campaign: "Traffic Acquisition - By Campaign",
    buy_adset: "Traffic Acquisition - By Adset",
    buy_ad: "Traffic Acquisition - By Ad",
    site: "Traffic Acquisition - By Publisher",
    spent_by_section: "Traffic Acquisition - By Section",
    industry: "Industry Insights",
    session_revenue: "Session Revenue Quantiles",
    ad_revenue: "Ad Revenue Index",
    session_time: "Session Time",
    request_reduction_client_v2: "Request Reduction Client v2",
    request_reduction_server_latency: "Health Report",
    request_reduction_server_samples_predict: "Request Reduction Server Samples (Predict)",
    request_reduction_server_samples_profile: "Request Reduction Server Samples",
    discrepancy_revenue: "Discrepancy Revenue",
    financial: "Financial Report",
    time_series_forecast: "Time Series Forecast",
    error_log: "Error Log",
    web: "Web Analytics",
    error_analytics: "Error Analytics",
    segments: "Segments",
    view_dispersion: "Visualize Dispersion",
};

export type IReport = {
    path: string; // the path without slashes (url= /view/path/)
    uuid: string;
    component: string; // which component to render
    title: string;
    description?: string;
    dimensions?: IDimension[];
    hide?: boolean; // hidden for api usage
    hasDemoVersion?: boolean;
    chartType?: "category" | "category_number" | "categoryTime" | "logarithmic" | "linear";
    pkg?:
        | "demand"
        | "adServer"
        | "implementation"
        | "custom"
        | "user"
        | "source"
        | "content"
        | "mapped"
        | "time"
        | "yieldManager";
    isAnalyticsReport?: boolean;
    isEarlyAccess?: boolean;
    isBeta?: boolean;
    placement: ReportPlacementKeyTypeInput;
    allowedDimensionIds: DimensionKeys[] | "yield_or_prebid_or_web_allowed_dimensions";
};

const buildReports = (reports: IReport[]): IReport[] =>
    reports.map(report => ({
        ...report,
        description: report.description ?? report.dimensions?.[0]?.description,
    }));
export const reports: IReport[] = buildReports([
    {
        path: "overview",
        uuid: "aa611175-0cbb-44b2-9dbd-ac2f9c8a168a",
        component: "ViewOverview",
        title: "Overview Prebid",
        placement: "__no_placement__",
        allowedDimensionIds: [],
    },
    {
        path: "overview_v2",
        uuid: "03d6bc4f-7aba-433b-a4d7-d7b3d33ae0b4",
        component: "ViewOverview",
        title: "Overview v2",
        placement: "__no_placement__",
        allowedDimensionIds: [],
    },
    {
        path: "overview_web",
        uuid: "925b1ab7-3ad5-4a11-a405-e626b1c8c48f",
        component: "ViewOverview",
        title: "Overview Web",
        placement: "__no_placement__",
        allowedDimensionIds: [],
    },
    {
        path: "industry/bidder",
        uuid: "2080401f-26d3-4f8f-a94e-7eab4f62c304",
        component: "View",
        title: "Industry - Bidder",
        description: "Last 30 days network wide bidder activity.",
        hide: true,
        dimensions: [dimensions.bidder],
        hasDemoVersion: true,
        placement: "industry",
        allowedDimensionIds: industryReportFiltersIds,
    },
    {
        path: "industry/size",
        uuid: "5eff1e14-7408-4a56-94f4-bbbe5b6ff1e9",
        component: "View",
        title: "Industry - Size",
        description: "Last 30 days network wide activity, by creative size.",
        hide: true,
        dimensions: [dimensions.size],
        hasDemoVersion: true,
        placement: "industry",
        allowedDimensionIds: industryReportFiltersIds,
    },
    {
        path: "industry/country",
        uuid: "758393db-a10c-4dbf-a701-115246cf857a",
        component: "View",
        title: "Industry - Country",
        description: "Last 30 days network wide activity, by country.",
        hide: true,
        dimensions: [dimensions.country],
        hasDemoVersion: true,
        placement: "industry",
        allowedDimensionIds: industryReportFiltersIds,
    },
    {
        path: "industry/device",
        uuid: "0b05c30b-5efa-4cb4-8cca-708ef4074684",
        component: "View",
        title: "Industry - Device",
        description: "Last 30 days network wide activity, by device.",
        hide: true,
        dimensions: [dimensions.device],
        hasDemoVersion: true,
        placement: "industry",
        allowedDimensionIds: industryReportFiltersIds,
    },
    {
        path: "industry/browser",
        uuid: "924581c2-fd04-47a5-96f0-30d702986c74",
        component: "View",
        title: "Industry - Browser",
        description: "Last 30 days network wide activity, by browser.",
        hide: true,
        dimensions: [dimensions.browser],
        hasDemoVersion: true,
        placement: "industry",
        allowedDimensionIds: industryReportFiltersIds,
    },
    {
        path: "generic",
        uuid: "99d554ff-609f-4ca9-a168-34d31c0618be",
        component: "View",
        title: "Generic",
        hide: true,
        dimensions: [],
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        path: "date",
        uuid: "09f48264-c8b5-470d-8ed4-4cb5f205ba69",
        component: "View",
        title: "Date",
        hide: true,
        dimensions: [dimensions.fullDate],
        chartType: "category",
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "demand",
        path: "bidder-prebid",
        uuid: "ac8dd0a3-7513-43b6-8ff0-4d89d9f66633",
        component: "View",
        title: "Bidder",
        dimensions: [dimensions.bidder_prebid],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "demand",
        path: "bidder",
        uuid: "9e9d0e07-bc25-4758-ba92-e547e526f15e",
        component: "View",
        title: "Winning Bidder",
        dimensions: [dimensions.winning_bidder],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "demand",
        path: "hb-bidder",
        uuid: "50d70d52-2be6-4bf8-89f5-f6b3c267825a",
        component: "View",
        title: "Highest Bidder",
        dimensions: [dimensions.highest_bidder],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "demand",
        path: "advertiserDomain",
        uuid: "ace04fd1-0a27-42df-9888-9d0eebd56bbb",
        component: "View",
        title: "Advertiser Domain",
        dimensions: [dimensions.advertiserDomain],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "demand",
        path: "dsp",
        uuid: "ab65e81c-6c01-44af-af02-157dcc017f9d",
        component: "View",
        title: "DSP",
        dimensions: [dimensions.dsp],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "demand",
        path: "buyer",
        uuid: "0eba523e-8af2-45f4-a595-19a992e0946b",
        component: "View",
        title: "Buyer",
        dimensions: [dimensions.buyer],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "demand",
        path: "brand",
        uuid: "4239c71d-02f5-451a-87dd-1a15da5e1e7a",
        component: "View",
        title: "Brand",
        dimensions: [dimensions.brand],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "demand",
        path: "deal",
        uuid: "97adfe03-e5e4-49b1-836d-a6061dd8a01c",
        component: "View",
        title: "Deal",
        dimensions: [dimensions.deal],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "demand",
        path: "media-type",
        uuid: "f6cf7c77-e1f7-4e86-8bec-aff13b172bc9",
        component: "View",
        title: "Media Type",
        dimensions: [dimensions.mediaType],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "demand",
        path: "bid",
        uuid: "a0a38067-325d-4d92-a170-b1ad4e0bbe5c",
        component: "View",
        title: "Bid",
        chartType: "logarithmic",
        dimensions: [dimensions.bid],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "demand",
        path: "time-to-respond",
        uuid: "5b0b7370-2a07-4e97-bfca-104424cbc81b",
        component: "View",
        title: "Time to Respond",
        chartType: "logarithmic",
        dimensions: [dimensions.timeToRespond],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "demand",
        path: "ad-quality-is-blocked",
        uuid: "e665f088-fee3-4a85-a4e6-58d87bfbbcd0",
        component: "View",
        title: "Ad Quality Is Blocked",
        dimensions: [dimensions.adQualityIsBlocked],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "demand",
        path: "ad-quality-blocking-type",
        uuid: "c1b6da7f-aaee-4a5f-b4a9-e0d36124c30e",
        component: "View",
        title: "Ad Quality Blocking Type",
        dimensions: [dimensions.adQualityBlockingType],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    /* Ad Server */
    {
        pkg: "adServer",
        path: "advertiser",
        uuid: "c802a490-528b-462a-8069-0e6412fccae5",
        component: "View",
        title: "GAM Advertiser",
        dimensions: [dimensions.dfpAdvertiser],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "adServer",
        path: "order",
        uuid: "9bf5533b-3730-4a9e-8961-12dc94436239",
        component: "View",
        title: "GAM Order",
        dimensions: [dimensions.dfpOrder],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "adServer",
        path: "lineItem",
        uuid: "99e4f8e1-19d5-4cbf-8d78-0f80c537697b",
        component: "View",
        title: "GAM Line Item",
        dimensions: [dimensions.dfpLineItem],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "adServer",
        path: "lineItemType",
        uuid: "8064ac9d-ce0f-4b2d-89b8-b0e32d4ace93",
        component: "View",
        title: "GAM Line Item Type",
        dimensions: [dimensions.dfpLineItemType],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "adServer",
        path: "creative",
        uuid: "9c9395c6-aa8c-4200-a5ec-0df7f4c710d1",
        component: "View",
        title: "GAM Creative",
        dimensions: [dimensions.dfpCreative],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "adServer",
        path: "hash",
        uuid: "dd0531d5-7fd3-4a68-839d-ab091bf5e322",
        component: "View",
        title: "AY Hash K/V",
        dimensions: [dimensions.dfpHash],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    /* Implementation */
    {
        pkg: "implementation",
        path: "ad-unit-code",
        uuid: "5d9e1862-d313-40ca-8ab0-257abaace814",
        component: "View",
        title: "Ad Unit Code",
        dimensions: [dimensions.adUnitCode],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "implementation",
        path: "placements",
        uuid: "d4e93954-d484-4b2f-b43d-3f8793bd5c1d",
        component: "View",
        title: "Placements",
        dimensions: [dimensions.placement],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "implementation",
        path: "ad-units",
        uuid: "dddb72d6-22d8-477b-aa44-45f557104dfa",
        component: "View",
        title: "Ad Units",
        dimensions: [dimensions.adUnit],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "mapped",
        path: "mapped-ad-unit",
        uuid: "9202a43f-17e4-4c43-811e-b666c0dc20b1",
        component: "View",
        title: "Ad Units",
        dimensions: [dimensions.mapped_adUnit],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "implementation",
        path: "sizes",
        uuid: "0c6348ed-bac6-4df5-9a07-d44b4a3dc65a",
        component: "View",
        title: "Creative Sizes",
        dimensions: [dimensions.size],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "implementation",
        path: "timeout",
        uuid: "c350da4b-fc28-4344-bea4-122fe85ed062",
        component: "View",
        title: "Timeout Setting",
        dimensions: [dimensions.timeout],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "implementation",
        path: "prebid-version",
        uuid: "1be323b2-7f9d-4f79-8716-681a07466d99",
        component: "View",
        title: "Prebid Version",
        dimensions: [dimensions.prebid_version],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "implementation",
        path: "assertive-version",
        uuid: "c6e512e8-3ff3-4c94-acbf-7c1a29679619",
        component: "View",
        title: "Assertive Version",
        dimensions: [dimensions.assertiveVersion],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "implementation",
        path: "floor-status",
        uuid: "1b6104c2-c47b-4859-90d4-7ae341e91fc0",
        component: "View",
        title: "Floor Status",
        dimensions: [dimensions.floor_status],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "implementation",
        path: "web-session-had-ads",
        uuid: "35d7d40f-370f-42f9-a58c-096b56eb7908",
        component: "View",
        title: "Session Had Ads",
        dimensions: [dimensions.sessionHadAds],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "implementation",
        path: "web-page-view-had-ads",
        uuid: "e77b67ca-1950-42ab-835c-dee52a9e1467",
        component: "View",
        title: "Page View Had Ads",
        dimensions: [dimensions.pageViewHadAds],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "implementation",
        path: "refresh-count",
        uuid: "cbc0aa09-6d79-4d99-aadf-f2d3c6b51b54",
        component: "View",
        title: "Ad Refresh Count",
        dimensions: [dimensions.refreshCount],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "implementation",
        path: "placement-render-count",
        uuid: "d2cf2e32-0c49-42cb-a7ea-6da5e81cc6f7",
        component: "View",
        title: "Placement Render Count",
        chartType: "logarithmic",
        dimensions: [dimensions.placementRenderCount],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "implementation",
        path: "prebid-user-id-provider",
        uuid: "764ac8bc-e3fd-4069-9016-058ba9cb91c6",
        component: "View",
        title: "PB UID Provider",
        description: "Prebid User ID Provider",
        dimensions: [dimensions.prebidUserIdProviderConfigured, dimensions.prebidUserIdProviderUsed],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "implementation",
        path: "prebid-source",
        uuid: "a01e099e-9856-4a4e-b039-7b0980064d09",
        component: "View",
        title: "Prebid Source",
        description: "Prebid Source",
        dimensions: [dimensions.prebid_source],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    /* Custom */
    {
        pkg: "custom",
        path: "userstate",
        uuid: "ea0f239d-9f34-4f33-a04c-b63f65c230fb",
        component: "View",
        title: "User State",
        dimensions: [dimensions.userState],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "custom",
        path: "layout",
        uuid: "da8f0678-04a3-4972-a901-76cc98bc71cb",
        component: "View",
        title: "Layout",
        dimensions: [dimensions.layout],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "custom",
        path: "custom-1",
        uuid: "457a6750-3b14-477f-baa2-97e62f5b2beb",
        component: "View",
        title: "Custom 1",
        dimensions: [dimensions.custom_1],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "custom",
        path: "custom-2",
        uuid: "1b831057-83f3-45ef-abfa-9aa192aed5a9",
        component: "View",
        title: "Custom 2",
        dimensions: [dimensions.custom_2],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "custom",
        path: "custom-3",
        uuid: "0091ada6-e3da-4f49-bee1-00b557d8b57b",
        component: "View",
        title: "Custom 3",
        dimensions: [dimensions.custom_3],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "custom",
        path: "custom-4",
        uuid: "a03c9343-e643-4bb6-9a32-d9354e6171dd",
        component: "View",
        title: "Custom 4",
        dimensions: [dimensions.custom_4],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "custom",
        path: "custom-5",
        uuid: "19e1d5e4-27a4-4664-9bbf-5db96588e157",
        component: "View",
        title: "Custom 5",
        dimensions: [dimensions.custom_5],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "custom",
        path: "custom-6",
        uuid: "93903e5b-0cd1-4d0a-8761-9ede897033e1",
        component: "View",
        title: "Custom 6",
        dimensions: [dimensions.custom_6],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "custom",
        path: "custom-7",
        uuid: "b173bbab-85af-4f62-b20f-48d929824746",
        component: "View",
        title: "Custom 7",
        dimensions: [dimensions.custom_7],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "custom",
        path: "custom-8",
        uuid: "9eac7ba3-589a-4c78-90e8-7884fd90b932",
        component: "View",
        title: "Custom 8",
        dimensions: [dimensions.custom_8],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "custom",
        path: "custom-9",
        uuid: "5461a359-d9ff-4b85-9a51-5da53618c73d",
        component: "View",
        title: "Custom 9",
        dimensions: [dimensions.custom_9],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "custom",
        path: "custom-10",
        uuid: "b0f27f01-7042-452c-88fd-3b40d2eca89f",
        component: "View",
        title: "Custom 10",
        dimensions: [dimensions.custom_10],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "custom",
        path: "custom-11",
        uuid: "a17a175f-5ae7-42c5-ae5b-bec4614395da",
        component: "View",
        title: "Custom 11",
        dimensions: [dimensions.custom_11],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "custom",
        path: "custom-12",
        uuid: "0da54acb-41ff-4b3e-9f7f-7a40ffc2eb51",
        component: "View",
        title: "Custom 12",
        dimensions: [dimensions.custom_12],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "custom",
        path: "custom-13",
        uuid: "90f35114-53ee-48ec-b04e-da34aaf0e9b4",
        component: "View",
        title: "Custom 13",
        dimensions: [dimensions.custom_13],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "custom",
        path: "custom-14",
        uuid: "57417798-8999-41c5-ad0f-9b75ebf6bdcb",
        component: "View",
        title: "Custom 14",
        dimensions: [dimensions.custom_14],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "custom",
        path: "custom-15",
        uuid: "c1ec4501-2ae2-4490-bf59-322dd536d0c1",
        component: "View",
        title: "Custom 15",
        dimensions: [dimensions.custom_15],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    /* User */
    {
        pkg: "user",
        path: "new-vs-returning",
        uuid: "96d44e6e-cf04-4894-b436-b7be5e2ae180",
        component: "View",
        title: "New vs Returning",
        dimensions: [dimensions.newVsReturning],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "user",
        path: "client-depth-sessions",
        uuid: "7148ab15-93b6-4c8e-a703-ef32432eeb3c",
        component: "View",
        title: "Device Depth Sessions",
        chartType: "logarithmic",
        dimensions: [dimensions.clientDepthSessions],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "user",
        path: "sessionDepthImpressions",
        uuid: "2f8e6c4e-455e-4ec5-9f8e-a4d3831490b1",
        component: "View",
        title: "Session Depth Impressions",
        chartType: "logarithmic",
        dimensions: [dimensions.sessionDepthImpressions],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "user",
        path: "sessionDepthPageViews",
        uuid: "0d3ea74f-5048-40b3-b25d-488429039350",
        component: "View",
        title: "Session Depth PageViews",
        chartType: "logarithmic",
        dimensions: [dimensions.sessionDepthPageViews],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "user",
        path: "devices",
        uuid: "3ab70c51-b765-4dca-a759-1b130d86a09b",
        component: "View",
        title: "Devices",
        dimensions: [dimensions.device],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "user",
        path: "operating-system",
        uuid: "46f64606-7add-4382-9521-ca90c84fe346",
        component: "View",
        title: "Operating System",
        dimensions: [dimensions.operatingSystem],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "user",
        path: "browser",
        uuid: "6c300534-17a5-4e50-8048-418ed2b964c4",
        component: "View",
        title: "Browser",
        dimensions: [dimensions.browser],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "user",
        path: "countries",
        uuid: "9709a225-560c-4c4d-92ab-174e0b5da7ca",
        component: "View",
        title: "Countries",
        dimensions: [dimensions.country],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "user",
        path: "regions",
        uuid: "06cc070f-c6f1-4dbd-b2a4-1c22aceb8c61",
        component: "View",
        title: "Regions",
        dimensions: [dimensions.region],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "user",
        path: "continents",
        uuid: "8af1a052-552c-4c82-ad56-cc76fd397023",
        component: "View",
        title: "Continents",
        dimensions: [dimensions.continent],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "user",
        path: "languages",
        uuid: "9196c454-17fb-44a9-b309-08544867de59",
        component: "View",
        title: "Languages",
        dimensions: [dimensions.language],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "user",
        path: "window-size",
        uuid: "cbf260f1-65b3-48b4-a592-778d804d4954",
        component: "View",
        title: "Window Size",
        dimensions: [dimensions.window_innerSize],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "user",
        path: "scroll-depth",
        uuid: "82114880-4646-4db4-a10b-8c892538727b",
        component: "View",
        title: "Scroll Depth",
        chartType: "logarithmic",
        dimensions: [dimensions.window_scrollY],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "user",
        path: "netwok-type",
        uuid: "d2edd863-054d-483c-9667-2d292ef732f4",
        component: "View",
        title: "Network Type",
        dimensions: [dimensions.network_type],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "user",
        path: "netwok-effective-type",
        uuid: "90d42e27-27b7-440b-8203-4e9a2f8344c3",
        component: "View",
        title: "Network Effective Type",
        dimensions: [dimensions.network_effectiveType],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "user",
        path: "netwok-downlink",
        uuid: "c1a59c45-2999-4eab-89e0-3f7d994f6395",
        component: "View",
        title: "Network Downlink",
        dimensions: [dimensions.network_downlink],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "user",
        path: "netwok-rtt",
        uuid: "d3de66a5-0c5f-4dd2-aaad-61dffe751f3c",
        component: "View",
        title: "Network RTT (ping)",
        chartType: "logarithmic",
        dimensions: [dimensions.network_rtt],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "user",
        path: "netwok-save-data",
        uuid: "4e99f119-7634-4b4d-85cd-8517942def39",
        component: "View",
        title: "Network Data-Saver",
        dimensions: [dimensions.network_saveData],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "user",
        path: "ivt-category",
        uuid: "ee022f95-8786-433d-add0-619014d7f198",
        component: "View",
        title: "IVT Category",
        dimensions: [dimensions.ivt_category],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "user",
        path: "ivt-type",
        uuid: "851ef074-831a-4d3e-aa52-defa96d17335",
        component: "View",
        title: "IVT Type",
        dimensions: [dimensions.ivt_type],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "user",
        path: "topics",
        uuid: "66a13a44-959a-4e60-a5bb-2ff9539a6bb7",
        component: "View",
        title: "Topics",
        dimensions: [dimensions.topics],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "user",
        path: "cookieDeprecationLabel",
        uuid: "004ed1e0-0da1-42e5-97f6-22ea7c8861af",
        component: "View",
        title: "Cookie Deprecation",
        dimensions: [dimensions.cookieDeprecationLabel],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    /* Source */
    {
        pkg: "source",
        path: "referrer",
        uuid: "3da7622e-4d96-4f7d-a766-f0fd43fc8ab5",
        component: "View",
        title: "Referrer Type",
        dimensions: [dimensions.referrerType],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "source",
        path: "referrer-domain",
        uuid: "56d16ec3-58c6-434c-9350-1e8cfbece64a",
        component: "View",
        title: "Referrer Domain",
        dimensions: [dimensions.referrerDomain],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "source",
        path: "referrer-url",
        uuid: "a00499b1-62f9-4d0d-99b2-d412777d62be",
        component: "View",
        title: "Referrer URL",
        dimensions: [dimensions.referrer],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "source",
        path: "utm-source",
        uuid: "880f728c-c6ed-42f2-aceb-f7ba7277694f",
        component: "View",
        title: "UTM Source",
        dimensions: [dimensions.utmSource],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "source",
        path: "utm-medium",
        uuid: "4b7e30fc-da22-4476-b778-b6589ff4e315",
        component: "View",
        title: "UTM Medium",
        dimensions: [dimensions.utmMedium],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "source",
        path: "utm-campaign",
        uuid: "cfb09991-b5cd-4483-9d7e-50a55f4348f0",
        component: "View",
        title: "UTM Campaign",
        dimensions: [dimensions.utmCampaign],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "source",
        path: "utm-term",
        uuid: "301dffb6-6dec-416b-84ba-fd4b45c93157",
        component: "View",
        title: "UTM Term",
        dimensions: [dimensions.utmTerm],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "source",
        path: "utm-content",
        uuid: "aea30928-d2b6-4996-be32-0127221873a8",
        component: "View",
        title: "UTM Content",
        dimensions: [dimensions.utmContent],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    /* Content */
    {
        pkg: "content",
        path: "request-reduction/client/status",
        uuid: "7b212e51-6ed7-4a07-8057-b76471058e9a",
        component: "View",
        title: "Status",
        hide: true,
        dimensions: [dimensions.request_reduction_client_status],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "content",
        path: "domain",
        uuid: "65b2fe26-cd57-4333-9c44-8dcb22b90981",
        component: "View",
        title: "Domain",
        dimensions: [dimensions.host],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "content",
        path: "entry-page",
        uuid: "69fe870f-d39a-44a1-aeb1-b447e7b6ceee",
        component: "View",
        title: "Entry Page",
        dimensions: [dimensions.entryPage],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "content",
        path: "content",
        uuid: "1a4e98d8-e1b0-4580-beeb-cbc23b2e69e6",
        component: "View",
        title: "Content",
        dimensions: [dimensions.content],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "content",
        path: "domain_and_content",
        uuid: "c644416a-0890-4b12-bc4e-9e948393e6b2",
        component: "View",
        title: "Page Domain Path",
        dimensions: [dimensions.domain_and_content],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "content",
        path: "pagelevel1-inf",
        uuid: "9db975c5-05ba-4d43-a9a7-ec375631e28b",
        component: "View",
        title: "Page Level 1 Inf",
        dimensions: [dimensions.pageLevel1Inf],
        hide: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "content",
        path: "pagelevel1",
        uuid: "b51063c1-946c-40f5-a33f-e2b61ef3e8da",
        component: "View",
        title: "Page Level 1",
        dimensions: [dimensions.pageLevel1],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "content",
        path: "pagelevel2",
        uuid: "44b09afb-8e37-4b7d-97ed-d543f284c67c",
        component: "View",
        title: "Page Level 2",
        dimensions: [dimensions.pageLevel2],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "content",
        path: "pagelevel3",
        uuid: "cae7c71c-78ea-4de8-99e7-bc4af4848889",
        component: "View",
        title: "Page Level 3",
        dimensions: [dimensions.pageLevel3],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "content",
        path: "pagelevel4",
        uuid: "aa118c79-42b1-4d5a-902f-2268a06cd28d",
        component: "View",
        title: "Page Level 4",
        dimensions: [dimensions.pageLevel4],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "content",
        path: "headline",
        uuid: "2f686f51-5c63-439b-9dc4-b1fb8d6e5cb1",
        component: "View",
        title: "Headline",
        dimensions: [dimensions.content_headline],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "content",
        path: "author",
        uuid: "e11fd17e-97a2-45bc-a20f-b8c8a7305b92",
        component: "View",
        title: "Author",
        dimensions: [dimensions.content_author],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "content",
        path: "datePublished",
        uuid: "feebe259-ff85-4674-9cda-9d61c91320a5",
        component: "View",
        title: "Date Published",
        dimensions: [dimensions.content_datePublished],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "content",
        path: "dateModified",
        uuid: "319a9232-b64c-47ee-9a75-0f4ec81a0416",
        component: "View",
        title: "Date Modified",
        dimensions: [dimensions.content_dateModified],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    // mapped
    {
        pkg: "mapped",
        path: "mapped-deal",
        uuid: "09c67646-0733-4205-a1d1-da721a62f565",
        component: "View",
        title: "Deal",
        dimensions: [dimensions.mapped_deal],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "mapped",
        path: "mapped-userstate",
        uuid: "fcd40c8b-ae38-400b-9658-5485b90197e2",
        component: "View",
        title: "User State",
        dimensions: [dimensions.mapped_userState],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "mapped",
        path: "mapped-layout",
        uuid: "35427f7e-b48d-4b5e-9858-4baf6598282f",
        component: "View",
        title: "Layout",
        dimensions: [dimensions.mapped_layout],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "mapped",
        path: "mapped-custom-1",
        uuid: "f2cd37f4-970c-4a45-8be5-0b925600aa42",
        component: "View",
        title: "Custom 1",
        dimensions: [dimensions.mapped_custom_1],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "mapped",
        path: "mapped-custom-2",
        uuid: "37eb2df7-5544-4134-aa8f-1e0c4f1cb085",
        component: "View",
        title: "Custom 2",
        dimensions: [dimensions.mapped_custom_2],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "mapped",
        path: "mapped-custom-3",
        uuid: "fa4ffdb5-f040-49f4-ad53-8b65c9cf2ebe",
        component: "View",
        title: "Custom 3",
        dimensions: [dimensions.mapped_custom_3],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "mapped",
        path: "mapped-custom-4",
        uuid: "3cda372e-32c8-49b3-a5e7-72c039631f74",
        component: "View",
        title: "Custom 4",
        dimensions: [dimensions.mapped_custom_4],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "mapped",
        path: "mapped-custom-5",
        uuid: "6ebe2e39-fdc2-47f1-9bbf-921598adb53f",
        component: "View",
        title: "Custom 5",
        dimensions: [dimensions.mapped_custom_5],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "mapped",
        path: "mapped-custom-6",
        uuid: "18f97499-ec1b-4f87-aa48-b0a1c86d4e0b",
        component: "View",
        title: "Custom 6",
        dimensions: [dimensions.mapped_custom_6],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "mapped",
        path: "mapped-custom-7",
        uuid: "a18acfa0-b358-40d9-85f7-9806da018718",
        component: "View",
        title: "Custom 7",
        dimensions: [dimensions.mapped_custom_7],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "mapped",
        path: "mapped-custom-8",
        uuid: "ccaaa954-1b72-46a9-940c-88b8a0b5f7cd",
        component: "View",
        title: "Custom 8",
        dimensions: [dimensions.mapped_custom_8],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "mapped",
        path: "mapped-custom-9",
        uuid: "e08aca01-dcd1-4e13-8c8b-a8ea49fe8cf3",
        component: "View",
        title: "Custom 9",
        dimensions: [dimensions.mapped_custom_9],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "mapped",
        path: "mapped-utm-source",
        uuid: "d97bf95f-1734-4426-a020-1e30e2278e54",
        component: "View",
        title: "UTM Source",
        dimensions: [dimensions.mapped_utmSource],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "mapped",
        path: "mapped-utm-medium",
        uuid: "b2657616-170c-413e-9bad-762b94b2517a",
        component: "View",
        title: "UTM Medium",
        dimensions: [dimensions.mapped_utmMedium],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "mapped",
        path: "mapped-utm-campaign",
        uuid: "42b1d027-cd8d-4ed2-b6d9-911b6f0b7574",
        component: "View",
        title: "UTM Campaign",
        dimensions: [dimensions.mapped_utmCampaign],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "mapped",
        path: "mapped-utm-term",
        uuid: "92b01776-9096-4ece-999e-4d400139b6f6",
        component: "View",
        title: "UTM Term",
        dimensions: [dimensions.mapped_utmTerm],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "mapped",
        path: "mapped-utm-content",
        uuid: "51bc79cb-3dd8-4a06-af61-b8db536ce2b2",
        component: "View",
        title: "UTM Content",
        dimensions: [dimensions.mapped_utmContent],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    /* Ad Server, mapped */
    {
        pkg: "mapped",
        path: "mapped-advertiser",
        uuid: "7ec0ed73-a4b4-4542-bae3-bfab7a90befe",
        component: "View",
        title: "GAM Advertiser",
        dimensions: [dimensions.mapped_dfpAdvertiser],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "mapped",
        path: "mapped-advertiser-id",
        uuid: "93a9a248-7f5d-4a11-991f-e73fbe9b478c",
        component: "View",
        title: "GAM Advertiser Id",
        dimensions: [dimensions.mapped_dfpAdvertiserId],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "mapped",
        path: "mapped-order",
        uuid: "e10a9ab3-d04a-4cf1-b5b4-a78db027a682",
        component: "View",
        title: "GAM Order",
        dimensions: [dimensions.mapped_dfpOrder],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "mapped",
        path: "mapped-order-id",
        uuid: "78ec3c42-0a65-47ba-b387-a4d5323ec458",
        component: "View",
        title: "GAM Order Id",
        dimensions: [dimensions.mapped_dfpOrderId],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "mapped",
        path: "mapped-lineItem",
        uuid: "9ddfdae8-38fd-4992-830f-cb1c4a985b94",
        component: "View",
        title: "GAM Line Item",
        dimensions: [dimensions.mapped_dfpLineItem],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "mapped",
        path: "mapped-lineItem-id",
        uuid: "df636c3d-9db6-43e1-adb1-5bf40bb2ec59",
        component: "View",
        title: "GAM Line Item Id",
        dimensions: [dimensions.mapped_dfpLineItemId],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "mapped",
        path: "mapped-creative",
        uuid: "daa66d2e-ad6e-43a7-81ed-6a7909fbb549",
        component: "View",
        title: "GAM Creative",
        dimensions: [dimensions.mapped_dfpCreative],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    // end mapped
    // start time-based
    {
        pkg: "time",
        path: "hourOfDay",
        uuid: "259f2daf-3da1-4736-9735-7810b09c36fe",
        component: "View",
        title: "Hour Of Day",
        dimensions: [dimensions.hourOfDay],
        isAnalyticsReport: true,
        chartType: "categoryTime",
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "time",
        path: "dayOfWeek",
        uuid: "35dca6bc-6556-48f1-a188-da1434c042a0",
        component: "View",
        title: "Day of the Week",
        dimensions: [dimensions.dayOfWeek],
        isAnalyticsReport: true,
        chartType: "categoryTime",
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "time",
        path: "dayOfMonth",
        uuid: "32555624-9524-41cc-8e9f-3f014b39e941",
        component: "View",
        title: "Day of the Month",
        dimensions: [dimensions.dayOfMonth],
        isAnalyticsReport: true,
        chartType: "categoryTime",
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "time",
        path: "monthOfYear",
        uuid: "0677a557-b9d7-4df8-bba4-e72667a985e4",
        component: "View",
        title: "Month of the Year",
        dimensions: [dimensions.monthOfYear],
        isAnalyticsReport: true,
        chartType: "categoryTime",
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "time",
        path: "timeZone",
        uuid: "a5b5a427-fd9f-46ed-ab29-a7fd18260624",
        component: "View",
        title: "Time Zone",
        dimensions: [dimensions.timeZone],
        isAnalyticsReport: true,
        chartType: "category",
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    // end time
    // start yield manager
    {
        pkg: "yieldManager",
        path: "yield-manager-status",
        uuid: "0df48c36-bc37-4609-96c1-4afe6b0ec2dc",
        component: "View",
        title: "Status",
        dimensions: [dimensions.manager_status],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "yieldManager",
        path: "yield-manager-deploy-id",
        uuid: "03f61822-600f-4932-b071-d30dabd4a62f",
        component: "View",
        title: "Deploy ID",
        description: "Yield Manager's deploy id.",
        dimensions: [dimensions.manager_deployId, dimensions.manager_deployedAt],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "yieldManager",
        path: "yield-manager-version-id",
        uuid: "c4e3964e-b770-404c-8a4a-1a9e6608a3be",
        component: "View",
        title: "Version ID",
        dimensions: [dimensions.manager_versionId],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        pkg: "yieldManager",
        path: "yield-manager-version-name",
        uuid: "e98f0112-9860-40c9-bc57-2bb537118d80",
        component: "View",
        title: "Version Name",
        dimensions: [dimensions.manager_versionName],
        isAnalyticsReport: true,
        placement: "yield_or_prebid_or_web",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    // end yield manager
    // start legacy
    {
        path: "sessionRevQuantiles",
        uuid: "8fcc408e-23b5-43ef-b024-9b896322c9e6",
        component: "ViewHPT",
        title: "Session Revenue Quantiles",
        placement: "session_revenue",
        allowedDimensionIds: yieldFilterIds,
        dimensions: [dimensions.country],
    },
    {
        path: "sessionTime",
        uuid: "351dec39-8d39-43dd-b89c-3cae5556acda",
        component: "ViewHPT",
        title: "Session Time",
        placement: "session_time",
        allowedDimensionIds: yieldFilterIds,
        dimensions: [dimensions.country],
    },
    // end legacy
    {
        path: "adRevenueIndex/global",
        uuid: "903b67e3-0512-46ab-82d5-1a9d5fefb40e",
        component: "View",
        title: "Ad Revenue Index - Global",
        description: "Network wide revenue.",
        hide: true,
        dimensions: [],
        hasDemoVersion: true,
        placement: "ad_revenue",
        allowedDimensionIds: adRevenueReportFilterIds,
    },
    {
        path: "adRevenueIndex/size",
        uuid: "efdae57c-cdf4-4b2e-96a0-4ee7dfd884b6",
        component: "View",
        title: "Ad Revenue Index - Size",
        description: "Network wide revenue, per creative size.",
        hide: true,
        dimensions: [dimensions.size],
        hasDemoVersion: true,
        placement: "ad_revenue",
        allowedDimensionIds: adRevenueReportFilterIds,
    },
    {
        path: "adRevenueIndex/country",
        uuid: "6ecc0e84-f77f-47d6-a40d-84f6fae0d94f",
        component: "View",
        title: "Ad Revenue Index - Country",
        description: "Network wide revenue, per country.",
        hide: true,
        dimensions: [dimensions.country],
        hasDemoVersion: true,
        placement: "ad_revenue",
        allowedDimensionIds: adRevenueReportFilterIds,
    },
    {
        path: "adRevenueIndex/device",
        uuid: "3cd47a69-535a-4b14-bcc3-57719ffed0b8",
        component: "View",
        title: "Ad Revenue Index - Device",
        description: "Network wide revenue, per device.",
        hide: true,
        dimensions: [dimensions.device],
        hasDemoVersion: true,
        placement: "ad_revenue",
        allowedDimensionIds: adRevenueReportFilterIds,
    },
    {
        path: "adRevenueIndex/browser",
        uuid: "d396fecd-9b2e-4866-86ca-7b7596e0fe40",
        component: "View",
        title: "Ad Revenue Index - Browser",
        description: "Network wide revenue, per browser.",
        hide: true,
        dimensions: [dimensions.browser],
        hasDemoVersion: true,
        placement: "ad_revenue",
        allowedDimensionIds: adRevenueReportFilterIds,
    },
    {
        path: "request-reduction/client/bidder",
        uuid: "719295c4-02de-496d-9c43-82357449f6db",
        component: "View",
        title: "Bidder",
        description: "Request reduction, by bidder.",
        hide: true,
        dimensions: [dimensions.request_reduction_client_v2_bidder],
        hasDemoVersion: false,
        placement: "request_reduction_client_v2",
        allowedDimensionIds: requestReductionClientV2FilterIds,
    },
    {
        path: "request-reduction/client/country",
        uuid: "3cf27376-cccd-46f8-a8a3-1efc0ce6fedd",
        component: "View",
        title: "Country",
        description: "Request reduction, by country.",
        hide: true,
        dimensions: [dimensions.request_reduction_client_v2_country],
        hasDemoVersion: false,
        placement: "request_reduction_client_v2",
        allowedDimensionIds: requestReductionClientV2FilterIds,
    },
    {
        path: "request-reduction/client/domain",
        uuid: "4bd22087-60cf-4380-b47b-7987906f5ea8",
        component: "View",
        title: "Domain",
        description: "Request reduction, by domain.",
        hide: true,
        dimensions: [dimensions.request_reduction_client_v2_host],
        hasDemoVersion: false,
        placement: "request_reduction_client_v2",
        allowedDimensionIds: requestReductionClientV2FilterIds,
    },
    {
        path: "request-reduction/client/device",
        uuid: "5195f532-b678-409a-bafa-af7a0169a387",
        component: "View",
        title: "Device Type",
        description: "Request reduction, by device type.",
        hide: true,
        dimensions: [dimensions.request_reduction_client_v2_device_name],
        hasDemoVersion: false,
        placement: "request_reduction_client_v2",
        allowedDimensionIds: requestReductionClientV2FilterIds,
    },
    {
        path: "request-reduction/client/os",
        uuid: "3b5e996d-17ae-4ee9-83d9-ab04ed17e785",
        component: "View",
        title: "Operating System",
        description: "Request reduction, by operating system.",
        hide: true,
        dimensions: [dimensions.request_reduction_client_v2_os_name],
        hasDemoVersion: false,
        placement: "request_reduction_client_v2",
        allowedDimensionIds: requestReductionClientV2FilterIds,
    },
    {
        path: "floor/status",
        uuid: "3e3d5ea7-a24f-4015-9352-d3bd30649f95",
        component: "View",
        title: "Floor Status",
        hide: true,
        dimensions: [dimensions.floor_status],
        hasDemoVersion: false,
        placement: "flooring",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        path: "floor/prebid",
        uuid: "8ab08e6c-dcd4-409f-895f-5d303896b7d7",
        component: "View",
        title: "Floor Prebid",
        hide: true,
        dimensions: [dimensions.floor_prebid],
        hasDemoVersion: false,
        placement: "flooring",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        path: "floor/amazon",
        uuid: "b30c32e0-d95a-11ed-afa1-0242ac120002",
        component: "View",
        title: "Floor Amazon",
        hide: true,
        dimensions: [dimensions.floor_amazon],
        hasDemoVersion: false,
        placement: "flooring",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        path: "floor/gpt",
        uuid: "031c5141-ba02-4fcb-848f-f089978ebc85",
        component: "View",
        title: "Floor GPT",
        hide: true,
        dimensions: [dimensions.floor_gpt],
        hasDemoVersion: false,
        placement: "flooring",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        path: "floor/group",
        uuid: "d703b990-6dc1-4ad4-bf3e-29427fc45a3e",
        component: "View",
        title: "Floor Group",
        hide: true,
        dimensions: [dimensions.floor_group],
        hasDemoVersion: false,
        placement: "flooring",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        path: "floor/threshold",
        uuid: "bce0e28d-67bd-4abf-8e59-5cfb098f3786",
        component: "View",
        title: "Floor Threshold",
        hide: true,
        dimensions: [dimensions.floor_threshold],
        hasDemoVersion: false,
        placement: "flooring",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        path: "floor/thresholdRaw",
        uuid: "e555c18e-e9ff-4de8-9725-bba3154b74e0",
        component: "View",
        title: "Floor Threshold Raw",
        hide: true,
        dimensions: [dimensions.floor_threshold_raw],
        hasDemoVersion: false,
        placement: "flooring",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        path: "floor/pbo",
        uuid: "8d22fde5-06b1-47a1-9b8a-a5d8dc5aa147",
        component: "View",
        title: "Floor PBO",
        hide: true,
        dimensions: [dimensions.floor_pbo],
        hasDemoVersion: false,
        placement: "flooring",
        allowedDimensionIds: "yield_or_prebid_or_web_allowed_dimensions",
    },
    {
        path: "request-reduction/server/samples-predict/threshold",
        uuid: "166fc858-567c-466a-bd65-16101751b14b",
        component: "View",
        title: "Threshold",
        hide: true,
        dimensions: [dimensions.request_reduction_server_samples_threshold],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_predict",
        allowedDimensionIds: requestReductionServerSamplesPredictFilterIds,
    },
    {
        path: "request-reduction/server/samples-predict/bid-confusion-matrix",
        uuid: "c40a9f9d-2333-4b9d-9142-1bde4877c640",
        component: "View",
        title: "Bid Confusion Matrix",
        hide: true,
        dimensions: [
            dimensions.request_reduction_server_samples_threshold,
            dimensions.request_reduction_server_samples_bid_confusion_matrix,
        ],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_predict",
        allowedDimensionIds: requestReductionServerSamplesPredictFilterIds,
    },
    {
        path: "request-reduction/server/samples-predict/win-confusion-matrix",
        uuid: "e7b35597-1457-49c0-ab26-5c3cc7fd323c",
        component: "View",
        title: "Win Confusion Matrix",
        hide: true,
        dimensions: [
            dimensions.request_reduction_server_samples_threshold,
            dimensions.request_reduction_server_samples_win_confusion_matrix,
        ],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_predict",
        allowedDimensionIds: requestReductionServerSamplesPredictFilterIds,
    },
    {
        path: "request-reduction/server/samples-predict/bidder",
        uuid: "0fd98e46-a87d-4a6e-93f2-9c31cae396d5",
        component: "View",
        title: "Bidder",
        hide: true,
        dimensions: [
            dimensions.request_reduction_server_samples_threshold,
            dimensions.request_reduction_server_samples_bidder,
        ],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_predict",
        allowedDimensionIds: requestReductionServerSamplesPredictFilterIds,
    },
    {
        path: "request-reduction/server/samples-predict/bidder-mapped",
        component: "View",
        title: "Bidder (mapped)",
        uuid: "b932d1a2-3b1a-495c-bd0a-72e4a9d841d6",
        hide: true,
        dimensions: [
            dimensions.request_reduction_server_samples_threshold,
            dimensions.request_reduction_server_samples_bidder_mapped,
        ],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_predict",
        allowedDimensionIds: requestReductionServerSamplesPredictFilterIds,
    },
    {
        path: "request-reduction/server/samples-predict/country",
        uuid: "360b2cbd-5c4a-4558-8f04-33f5923c9fdd",
        component: "View",
        title: "Country",
        hide: true,
        dimensions: [
            dimensions.request_reduction_server_samples_threshold,
            dimensions.request_reduction_server_samples_country,
        ],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_predict",
        allowedDimensionIds: requestReductionServerSamplesPredictFilterIds,
    },
    {
        path: "request-reduction/server/samples-predict/datacenter",
        uuid: "ccb0ee0f-f90e-49e5-844a-1c440e3e689f",
        component: "View",
        title: "Data Center",
        hide: true,
        dimensions: [
            dimensions.request_reduction_server_samples_threshold,
            dimensions.request_reduction_server_samples_datacenter,
        ],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_predict",
        allowedDimensionIds: requestReductionServerSamplesPredictFilterIds,
    },
    {
        path: "request-reduction/server/samples-predict/publisher_id",
        uuid: "69f625a4-17d4-407c-b519-3c1fba6b5dd7",
        component: "View",
        title: "Publisher ID",
        hide: true,
        dimensions: [
            dimensions.request_reduction_server_samples_threshold,
            dimensions.request_reduction_server_samples_publisher_id,
        ],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_predict",
        allowedDimensionIds: requestReductionServerSamplesPredictFilterIds,
    },
    {
        path: "request-reduction/server/samples-predict/placement_id",
        uuid: "b936f911-6993-43eb-8c11-7659ccbff20c",
        component: "View",
        title: "Placement ID",
        hide: true,
        dimensions: [
            dimensions.request_reduction_server_samples_threshold,
            dimensions.request_reduction_server_samples_placement_id,
        ],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_predict",
        allowedDimensionIds: requestReductionServerSamplesPredictFilterIds,
    },
    {
        path: "request-reduction/server/samples-predict/request_type",
        uuid: "907be25c-7522-46d1-9193-97a79c23362d",
        component: "View",
        title: "Request Type",
        hide: true,
        dimensions: [
            dimensions.request_reduction_server_samples_threshold,
            dimensions.request_reduction_server_samples_request_type,
        ],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_predict",
        allowedDimensionIds: requestReductionServerSamplesPredictFilterIds,
    },
    {
        path: "request-reduction/server/samples-predict/format",
        uuid: "e1347ab2-79c4-447f-9eb1-6a2698f49529",
        component: "View",
        title: "Format",
        hide: true,
        dimensions: [
            dimensions.request_reduction_server_samples_threshold,
            dimensions.request_reduction_server_samples_format,
        ],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_predict",
        allowedDimensionIds: requestReductionServerSamplesPredictFilterIds,
    },
    {
        path: "request-reduction/server/samples-predict/sizes",
        uuid: "bc7dcd4f-ff23-4335-84f1-dfd5739fbe89",
        component: "View",
        title: "Sizes",
        hide: true,
        dimensions: [
            dimensions.request_reduction_server_samples_threshold,
            dimensions.request_reduction_server_samples_sizes,
        ],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_predict",
        allowedDimensionIds: requestReductionServerSamplesPredictFilterIds,
    },
    {
        path: "request-reduction/server/samples-predict/platform",
        uuid: "1685acd5-b34b-4410-8f82-15ecda0de4a5",
        component: "View",
        title: "Platform",
        hide: true,
        dimensions: [
            dimensions.request_reduction_server_samples_threshold,
            dimensions.request_reduction_server_samples_platform,
        ],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_predict",
        allowedDimensionIds: requestReductionServerSamplesPredictFilterIds,
    },
    {
        path: "request-reduction/server/samples-predict/bundle",
        uuid: "89e96d3f-c77f-4ff5-b827-8b77802f0127",
        component: "View",
        title: "Bundle",
        hide: true,
        dimensions: [
            dimensions.request_reduction_server_samples_threshold,
            dimensions.request_reduction_server_samples_bundle,
        ],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_predict",
        allowedDimensionIds: requestReductionServerSamplesPredictFilterIds,
    },
    {
        path: "request-reduction/server/samples-predict/os",
        uuid: "1ceac51e-8aae-48e2-b32d-1b37ac80c1fa",
        component: "View",
        title: "Operating System",
        hide: true,
        dimensions: [
            dimensions.request_reduction_server_samples_threshold,
            dimensions.request_reduction_server_samples_os,
        ],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_predict",
        allowedDimensionIds: requestReductionServerSamplesPredictFilterIds,
    },
    {
        path: "request-reduction/server/samples-predict/browser",
        uuid: "6cc64ad3-9670-404a-82df-8621bfc8e919",
        component: "View",
        title: "Browser",
        hide: true,
        dimensions: [
            dimensions.request_reduction_server_samples_threshold,
            dimensions.request_reduction_server_samples_browser,
        ],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_predict",
        allowedDimensionIds: requestReductionServerSamplesPredictFilterIds,
    },
    {
        path: "request-reduction/server/samples-predict/device",
        uuid: "2de9e298-cf7a-4042-babc-011718633e05",
        component: "View",
        title: "Device",
        hide: true,
        dimensions: [
            dimensions.request_reduction_server_samples_threshold,
            dimensions.request_reduction_server_samples_device,
        ],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_predict",
        allowedDimensionIds: requestReductionServerSamplesPredictFilterIds,
    },
    {
        path: "request-reduction/server/samples-predict/codebase_version",
        uuid: "d64a0cc9-334c-4ee3-8250-28a797615174",
        component: "View",
        title: "ML Codebase Version",
        hide: true,
        dimensions: [
            dimensions.request_reduction_server_samples_threshold,
            dimensions.request_reduction_server_samples_codebase_version,
        ],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_predict",
        allowedDimensionIds: requestReductionServerSamplesPredictFilterIds,
    },
    {
        path: "request-reduction/server/samples-predict/version",
        uuid: "564c78a8-da6a-442d-bd6e-c6d7ff50806a",
        component: "View",
        title: "Version",
        hide: true,
        dimensions: [
            dimensions.request_reduction_server_samples_threshold,
            dimensions.request_reduction_server_samples_version,
        ],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_predict",
        allowedDimensionIds: requestReductionServerSamplesPredictFilterIds,
    },
    {
        path: "request-reduction/server/samples-predict/device-language",
        uuid: "bed86543-148f-45b3-9359-5e9cc8f2d958",
        component: "View",
        title: "Device Language",
        hide: true,
        dimensions: [
            dimensions.request_reduction_server_samples_threshold,
            dimensions.request_reduction_server_samples_device_language,
        ],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_predict",
        allowedDimensionIds: requestReductionServerSamplesPredictFilterIds,
    },
    {
        path: "request-reduction/server/samples-predict/url",
        uuid: "dd1405b7-b775-492f-a320-120139c635fb",
        component: "View",
        title: "URL",
        hide: true,
        dimensions: [
            dimensions.request_reduction_server_samples_threshold,
            dimensions.request_reduction_server_samples_url,
        ],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_predict",
        allowedDimensionIds: requestReductionServerSamplesPredictFilterIds,
    },
    {
        path: "request-reduction/server/samples-predict/domain",
        uuid: "9cda1e8e-0685-4ba2-b2c8-c3225ebd1ba1",
        component: "View",
        title: "Domain",
        hide: true,
        dimensions: [
            dimensions.request_reduction_server_samples_threshold,
            dimensions.request_reduction_server_samples_domain,
        ],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_predict",
        allowedDimensionIds: requestReductionServerSamplesPredictFilterIds,
    },
    {
        path: "request-reduction/server/samples-predict/custom1",
        uuid: "bd28adce-89f8-4296-a007-d238fde21dec",
        component: "View",
        title: "Custom 1",
        hide: true,
        dimensions: [
            dimensions.request_reduction_server_samples_threshold,
            dimensions.request_reduction_server_samples_custom1,
        ],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_predict",
        allowedDimensionIds: requestReductionServerSamplesPredictFilterIds,
    },
    {
        path: "request-reduction/server/samples-predict/custom2",
        uuid: "57853de4-4688-476f-b9f1-9af87f290c3e",
        component: "View",
        title: "Custom 2",
        hide: true,
        dimensions: [
            dimensions.request_reduction_server_samples_threshold,
            dimensions.request_reduction_server_samples_custom2,
        ],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_predict",
        allowedDimensionIds: requestReductionServerSamplesPredictFilterIds,
    },
    {
        path: "request-reduction/server/samples-predict/custom3",
        uuid: "e9eb0885-c139-47cf-8b33-98105a0d74c1",
        component: "View",
        title: "Custom 3",
        hide: true,
        dimensions: [
            dimensions.request_reduction_server_samples_threshold,
            dimensions.request_reduction_server_samples_custom3,
        ],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_predict",
        allowedDimensionIds: requestReductionServerSamplesPredictFilterIds,
    },
    // collect
    {
        path: "request-reduction/server/samples-profile/bidder",
        uuid: "7a9eddc6-f8e6-42e0-aae6-6bba29930a43",
        component: "View",
        title: "Bidder",
        hide: true,
        dimensions: [dimensions.request_reduction_server_samples_bidder],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_profile",
        allowedDimensionIds: requestReductionServerSamplesProfileFilterIds,
    },
    {
        path: "request-reduction/server/samples-profile/bidder-mapped",
        component: "View",
        title: "Bidder (mapped)",
        uuid: "ceb8110a-4283-4775-af2c-e62e5c7bb5df",
        hide: true,
        dimensions: [dimensions.request_reduction_server_samples_bidder_mapped],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_profile",
        allowedDimensionIds: requestReductionServerSamplesProfileFilterIds,
    },
    {
        path: "request-reduction/server/samples-profile/country",
        uuid: "245e74e0-6a31-40ca-b44a-2b97295e5c60",
        component: "View",
        title: "Country",
        hide: true,
        dimensions: [dimensions.request_reduction_server_samples_country],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_profile",
        allowedDimensionIds: requestReductionServerSamplesProfileFilterIds,
    },
    {
        path: "request-reduction/server/samples-profile/publisher_id",
        uuid: "dbbffe0d-c9cd-48b3-ae3f-49f8ba394669",
        component: "View",
        title: "Publisher ID",
        hide: true,
        dimensions: [dimensions.request_reduction_server_samples_publisher_id],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_profile",
        allowedDimensionIds: requestReductionServerSamplesProfileFilterIds,
    },
    {
        path: "request-reduction/server/samples-profile/placement_id",
        uuid: "e307d104-b662-465c-b148-79cae16f40a8",
        component: "View",
        title: "Placement ID",
        hide: true,
        dimensions: [dimensions.request_reduction_server_samples_placement_id],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_profile",
        allowedDimensionIds: requestReductionServerSamplesProfileFilterIds,
    },
    {
        path: "request-reduction/server/samples-profile/request_type",
        uuid: "2a483b27-e6fa-474a-8a75-47385d2f7aa9",
        component: "View",
        title: "Request Type",
        hide: true,
        dimensions: [dimensions.request_reduction_server_samples_request_type],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_profile",
        allowedDimensionIds: requestReductionServerSamplesProfileFilterIds,
    },
    {
        path: "request-reduction/server/samples-profile/format",
        uuid: "509511e5-8693-42c6-b66a-ca3df182411d",
        component: "View",
        title: "Format",
        hide: true,
        dimensions: [dimensions.request_reduction_server_samples_format],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_profile",
        allowedDimensionIds: requestReductionServerSamplesProfileFilterIds,
    },
    {
        path: "request-reduction/server/samples-profile/sizes",
        uuid: "cc2b7465-1aa5-4fdf-92c4-ef29c069d093",
        component: "View",
        title: "Sizes",
        hide: true,
        dimensions: [dimensions.request_reduction_server_samples_sizes],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_profile",
        allowedDimensionIds: requestReductionServerSamplesProfileFilterIds,
    },
    {
        path: "request-reduction/server/samples-profile/platform",
        uuid: "8056cb90-7d15-4f82-910f-153e2da15930",
        component: "View",
        title: "Platform",
        hide: true,
        dimensions: [dimensions.request_reduction_server_samples_platform],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_profile",
        allowedDimensionIds: requestReductionServerSamplesProfileFilterIds,
    },
    {
        path: "request-reduction/server/samples-profile/bundle",
        uuid: "695a421d-5922-45b8-b267-e6de5ead6406",
        component: "View",
        title: "Bundle",
        hide: true,
        dimensions: [dimensions.request_reduction_server_samples_bundle],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_profile",
        allowedDimensionIds: requestReductionServerSamplesProfileFilterIds,
    },
    {
        path: "request-reduction/server/samples-profile/os",
        uuid: "13d17b17-ae88-4577-9e82-364ba66bf137",
        component: "View",
        title: "Operating System",
        hide: true,
        dimensions: [dimensions.request_reduction_server_samples_os],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_profile",
        allowedDimensionIds: requestReductionServerSamplesProfileFilterIds,
    },
    {
        path: "request-reduction/server/samples-profile/browser",
        uuid: "b8dbeb48-e63c-4f7d-bf2f-c850d3b61222",
        component: "View",
        title: "Browser",
        hide: true,
        dimensions: [dimensions.request_reduction_server_samples_browser],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_profile",
        allowedDimensionIds: requestReductionServerSamplesProfileFilterIds,
    },
    {
        path: "request-reduction/server/samples-profile/device",
        uuid: "e1ed66ca-7d68-432d-83f0-a7b06b19890f",
        component: "View",
        title: "Device",
        hide: true,
        dimensions: [dimensions.request_reduction_server_samples_device],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_profile",
        allowedDimensionIds: requestReductionServerSamplesProfileFilterIds,
    },
    {
        path: "request-reduction/server/samples-profile/codebase_version",
        uuid: "2c7b6ea7-9c02-4bd1-b4af-6b46c2b5fdd8",
        component: "View",
        title: "ML Codebase Version",
        hide: true,
        dimensions: [dimensions.request_reduction_server_samples_codebase_version],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_profile",
        allowedDimensionIds: requestReductionServerSamplesProfileFilterIds,
    },
    {
        path: "request-reduction/server/samples-profile/version",
        uuid: "37b00666-0478-4f48-95ce-9ce9b0043373",
        component: "View",
        title: "Version",
        hide: true,
        dimensions: [dimensions.request_reduction_server_samples_version],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_profile",
        allowedDimensionIds: requestReductionServerSamplesProfileFilterIds,
    },
    {
        path: "request-reduction/server/samples-profile/device-language",
        uuid: "53222616-223c-40b6-a760-ef56321713ca",
        component: "View",
        title: "Device Language",
        hide: true,
        dimensions: [dimensions.request_reduction_server_samples_device_language],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_profile",
        allowedDimensionIds: requestReductionServerSamplesProfileFilterIds,
    },
    {
        path: "request-reduction/server/samples-profile/url",
        uuid: "f928a84e-5ff8-4dff-8099-6df98628ea23",
        component: "View",
        title: "URL",
        hide: true,
        dimensions: [dimensions.request_reduction_server_samples_url],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_profile",
        allowedDimensionIds: requestReductionServerSamplesProfileFilterIds,
    },
    {
        path: "request-reduction/server/samples-profile/domain",
        uuid: "c1ca5545-e044-4a5e-bfef-051f51b6bfb6",
        component: "View",
        title: "Domain",
        hide: true,
        dimensions: [dimensions.request_reduction_server_samples_domain],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_profile",
        allowedDimensionIds: requestReductionServerSamplesProfileFilterIds,
    },
    {
        path: "request-reduction/server/samples-profile/custom1",
        uuid: "1a034aa6-d7bd-4517-a573-2d28ce9180c1",
        component: "View",
        title: "Custom 1",
        hide: true,
        dimensions: [dimensions.request_reduction_server_samples_custom1],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_profile",
        allowedDimensionIds: requestReductionServerSamplesProfileFilterIds,
    },
    {
        path: "request-reduction/server/samples-profile/custom2",
        uuid: "7480540b-dc01-4ec0-bd1e-0d9cb5cbcd8b",
        component: "View",
        title: "Custom 2",
        hide: true,
        dimensions: [dimensions.request_reduction_server_samples_custom2],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_profile",
        allowedDimensionIds: requestReductionServerSamplesProfileFilterIds,
    },
    {
        path: "request-reduction/server/samples-profile/custom3",
        uuid: "0186bddb-80fe-430e-b9ad-73a2717fafd5",
        component: "View",
        title: "Custom 3",
        hide: true,
        dimensions: [dimensions.request_reduction_server_samples_custom3],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_profile",
        allowedDimensionIds: requestReductionServerSamplesProfileFilterIds,
    },
    {
        path: "request-reduction/server/samples-profile/datacenter",
        uuid: "a33bb3b7-25c4-4680-83ba-b6e633510bb9",
        component: "View",
        title: "Data Center",
        hide: true,
        dimensions: [dimensions.request_reduction_server_samples_datacenter],
        hasDemoVersion: false,
        placement: "request_reduction_server_samples_profile",
        allowedDimensionIds: requestReductionServerSamplesProfileFilterIds,
    },
    // end collect
    {
        path: "request-reduction/server/health-report",
        uuid: "ba15c603-63d5-44ca-bf39-d5348682bf07",
        component: "View",
        title: "Health Report",
        description: "Reports on the latency added by the traffic shaping module.",
        hide: true,
        dimensions: [dimensions.request_reduction_server_datacenter],
        hasDemoVersion: false,
        placement: "request_reduction_server_latency",
        allowedDimensionIds: requestReductionServerLatencyFilterIds,
    },
    {
        path: "logs/error",
        uuid: "e1842e16-600d-4542-9d6d-64d5f9127bdb",
        component: "ViewErrorLog",
        title: "Error Logs",
        placement: "__no_placement__",
        allowedDimensionIds: [],
    },
    {
        path: "logs/api",
        uuid: "3b9d33a2-741e-4cf4-9057-9e7be6d880ca",
        component: "ViewApiLog",
        title: "API Logs",
        placement: "__no_placement__",
        allowedDimensionIds: [],
    },
    {
        path: "logs/spent",
        uuid: "13f0bdad-b232-455e-84b1-0d0a84dbae84",
        component: "ViewJobLog",
        title: "Spent Import Logs",
        placement: "__no_placement__",
        allowedDimensionIds: [],
    },
    {
        path: "logs/revenue",
        uuid: "504581fd-af71-4348-8311-400a73ea5b2c",
        component: "ViewJobLog",
        title: "Revenue Import logs",
        placement: "__no_placement__",
        allowedDimensionIds: [],
    },
    {
        path: "logs/server_to_server",
        uuid: "06eb15cd-ef63-4427-84a6-2d8befec634f",
        component: "ViewJobLog",
        title: "S2S logs",
        placement: "__no_placement__",
        allowedDimensionIds: [],
    },
    {
        component: "View",
        path: "spent/campaign",
        uuid: "5ae346ab-fb43-44d3-8348-8f4cd218b14f",
        title: "Campaign Report",
        description: "Summary of revenue with expenses, on campaign level",
        hide: true,
        isEarlyAccess: true,
        dimensions: [dimensions.buy_report_platform, dimensions.buy_report_campaign_name],
        hasDemoVersion: false,
        placement: "buy_campaign",
        allowedDimensionIds: spentCampaignFilterIds,
    },
    {
        component: "View",
        path: "spent/adset",
        uuid: "8fe26fa1-b3eb-47fd-965d-340460741a3a",
        title: "Adset Report",
        description: "Summary of revenue with expenses, on adset level",
        hide: true,
        isEarlyAccess: true,
        dimensions: [
            dimensions.buy_report_platform,
            dimensions.buy_report_campaign_name,
            dimensions.buy_report_adset_name,
        ],
        hasDemoVersion: false,
        placement: "buy_adset",
        allowedDimensionIds: spentAdsetFilterIds,
    },
    {
        component: "View",
        path: "spent/ad",
        uuid: "575c7b72-8e78-4c7b-94bf-6af8ebc161f0",
        title: "Ad Report",
        description: "Summary of revenue with expenses, on ad level",
        hide: true,
        isEarlyAccess: true,
        dimensions: [
            dimensions.buy_report_platform,
            dimensions.buy_report_campaign_name,
            dimensions.buy_report_adset_name,
            dimensions.buy_report_ad_name,
        ],
        hasDemoVersion: false,
        placement: "buy_ad",
        allowedDimensionIds: spentAdsFilterIds,
    },
    {
        component: "View",
        path: "spent/publisher",
        uuid: "317a8ba6-2713-42a5-aa78-bbf3997e8b1f",
        title: "Publisher Report",
        description: "Summary of revenue with expenses, per publisher (site)",
        hide: true,
        isEarlyAccess: true,
        dimensions: [dimensions.site_report_platform, dimensions.site_report_site_name],
        hasDemoVersion: false,
        placement: "site",
        allowedDimensionIds: siteReportFilterIds,
    },
    {
        component: "View",
        path: "spent/section",
        uuid: "8e93ba40-6fd3-4bb0-9a02-7048a28f4d8e",
        title: "Section Report",
        description: "Summary of revenue with expenses, per section",
        hide: true,
        isEarlyAccess: true,
        dimensions: [dimensions.spent_by_section_report_platform, dimensions.spent_by_section_report_section_name],
        hasDemoVersion: false,
        placement: "spent_by_section",
        allowedDimensionIds: spentBySectionReportFilterIds,
    },
    {
        component: "View",
        path: "connected-ssps/discrepancy",
        uuid: "9e695d52-37ed-49b0-95de-a1b92c28571f",
        title: "Discrepancy Revenue",
        description: "Discrepancy Revenue between connected SSP's and Assertive Events",
        hide: true,
        isEarlyAccess: true,
        dimensions: [dimensions.discrepancy_revenue_bidder],
        hasDemoVersion: false,
        placement: "discrepancy_revenue",
        allowedDimensionIds: discrepancyRevenueReportFilterIds,
    },
    {
        path: "timeSeriesForecast",
        uuid: "35f1a860-0cfe-4aae-97a5-db03b051118a",
        component: "ViewTimeSeriesForecast",
        title: "Time Series Forecast",
        placement: "time_series_forecast",
        allowedDimensionIds: yieldFilterIds,
        dimensions: [],
    },
    {
        path: "debugDispersion",
        uuid: "b701133b-4c8b-4cb9-86b6-d40efc9cdc10",
        component: "ViewDispersion",
        title: "Debug Dispersion",
        placement: "view_dispersion",
        allowedDimensionIds: yieldFilterIds,
        dimensions: [],
        hide: true,
    },
    {
        component: "View",
        path: "connected-ssps/financial",
        uuid: "337649ed-0c4f-4ab6-b556-1e3fb85c0894",
        title: "Financial",
        description: "Financial Report from connected SSP's",
        hide: true,
        isEarlyAccess: true,
        dimensions: [dimensions.financial_bidder],
        hasDemoVersion: false,
        placement: "financial",
        allowedDimensionIds: financialReportFilterIds,
    },
]);
export const isGenericReportUuid = (uuid: string): boolean => uuid == "99d554ff-609f-4ca9-a168-34d31c0618be";

export const GENERIC_REPORT: IReport = reports.find(it => isGenericReportUuid(it.uuid))!;

export const yieldReports = reports
    .map(it => {
        if (isGenericReportUuid(it.uuid)) {
            return {
                ...it,
                title: "Yield Report",
            };
        }
        return it;
    })
    .filter(report => {
        const isPrebidReport =
            !!(report.isAnalyticsReport && (report?.dimensions?.[0]?.analytics_reports ?? []).includes("yield")) ||
            isGenericReportUuid(report.uuid);
        return isPrebidReport;
    });
export const prebidReports = reports
    .map(it => {
        if (isGenericReportUuid(it.uuid)) {
            return {
                ...it,
                title: "Prebid Report",
            };
        }
        return it;
    })
    .filter(report => {
        const isPrebidReport =
            !!(report.isAnalyticsReport && (report?.dimensions?.[0]?.analytics_reports ?? []).includes("prebid")) ||
            isGenericReportUuid(report.uuid);
        return isPrebidReport;
    });

export const webReports = reports
    .map(it => {
        if (isGenericReportUuid(it.uuid)) {
            return {
                ...it,
                title: "Web Report",
            };
        }
        return it;
    })
    .filter(report => {
        const isPrebidReport =
            !!(report.isAnalyticsReport && (report?.dimensions?.[0]?.analytics_reports ?? []).includes("web")) ||
            isGenericReportUuid(report.uuid);
        return isPrebidReport;
    });

export const getReportsByPlacement = (placement: string): IReport[] => {
    switch (placement) {
        case "yield":
            return yieldReports;
        case "prebid":
            return prebidReports;
        case "web":
            return webReports;
        case "buy_campaign":
            return reports.filter(it => it.uuid === "5ae346ab-fb43-44d3-8348-8f4cd218b14f");
        case "buy_adset":
            return reports.filter(it => it.uuid === "8fe26fa1-b3eb-47fd-965d-340460741a3a");
        case "buy_ad":
            return reports.filter(it => it.uuid === "575c7b72-8e78-4c7b-94bf-6af8ebc161f0");
        case "site":
            return reports.filter(it => it.uuid === "317a8ba6-2713-42a5-aa78-bbf3997e8b1f");
        case "spent_by_section":
            return reports.filter(it => it.uuid === "8e93ba40-6fd3-4bb0-9a02-7048a28f4d8e");
        default: {
            throw new Error("not implemented call to getReportsByPlacement with placement: " + placement);
        }
    }
};
