import type {
    DiscrepancyRevenueReportRawMetrics,
    FinancialReportMetrics,
    RawErrorAnalyticsMetrics,
    RawPrebidMetrics,
    RawRequestReductionClientV2Metrics,
    RawRequestReductionServerSamplesMetrics,
    RawWebMetrics,
    RawYieldMetrics,
} from "./metrics.constants";
import type { ReportPlacementKeyType } from "./placement.constants";

export const QUERY_CURRENCY_KEY = `%%QUERY_CURRENCY%%`;
export const REVENUE_BIAS_MULTIPLY = `%%REVENUE_BIAS_MULTIPLY%%`;
export const TABLE_NAME = `%%TABLE_NAME%%`;
export const maxDaTimestampKey = "%%MAX_DA_TIMESTAMP_PER_ENTITY%%";
export const timezoneKey = "%%TIMEZONE%%";
export const REGEX_TO_CLEAR_BIDDER = "^video_|^cnx_|^aditude_|s2s$|_s2s$|FsClientAux$";

const clickBounceThreshold = 10000;

const currencyConversionFactorE = (fromCurrency: string) =>
    `dictGetFloat64('currencyConversion2', 'value', tuple(toDate(${TABLE_NAME}.timestamp), ${QUERY_CURRENCY_KEY})) / dictGetFloat64('currencyConversion2', 'value', tuple(toDate(${TABLE_NAME}.timestamp), ${fromCurrency} ))`;

const currencyConversionFactorPV = (fromCurrency: string) =>
    `dictGetFloat64('currencyConversion2', 'value', tuple(toDate(${TABLE_NAME}.timestamp), ${QUERY_CURRENCY_KEY})) / dictGetFloat64('currencyConversion2', 'value', tuple(toDate(${TABLE_NAME}.timestamp), ${fromCurrency} ))`;

const convertToCurrencyCreator =
    (currencyConversionFactor: (fromCurrency: string) => string) => (fromCurrency: string) =>
        `if( ${fromCurrency} = '', 1.0, ${currencyConversionFactor(fromCurrency)} )`;

const convertToCurrency = convertToCurrencyCreator(currencyConversionFactorE);

const getCurrencyFromLineItem = `dictGetStringOrDefault('lineItem', 'currency', toUInt64(assumeNotNull(dfp_lineItemId)), '')`;
const convertLineItemCurrency = convertToCurrency(getCurrencyFromLineItem);

const nativoRevenueCurrencyConverted = (field: string): string => {
    // nativo is always USD
    return `(${field} * ${convertToCurrency("'USD'")})`;
};

export const lineItemLookup = `if(dictGetString('lineItem', 'costType', toUInt64(assumeNotNull(dfp_lineItemId))) = 'CPM', if(event_impression, toFloat64(dictGetUInt64('lineItem', 'value', toUInt64(assumeNotNull(dfp_lineItemId)))), 0), toFloat64(assumeNotNull(revenue))) / 1000`;
const lineItemLookupWithCurrency = `if(dictGetString('lineItem', 'costType', toUInt64(assumeNotNull(dfp_lineItemId))) = 'CPM', if(event_impression, toFloat64(dictGetUInt64('lineItem', 'value', toUInt64(assumeNotNull(dfp_lineItemId)))), 0) * ${convertLineItemCurrency}, toFloat64(assumeNotNull(revenue))) / 1000`;
const directRevenueField = `sumIf(${lineItemLookupWithCurrency} ${REVENUE_BIAS_MULTIPLY}, source = 'lineitem')`;
const directRevenueFieldNoCurrencyConversion = `sumIf(${lineItemLookup} ${REVENUE_BIAS_MULTIPLY}, source = 'lineitem')`;
const lineItemRevenueField = `sumIf(${lineItemLookupWithCurrency} ${REVENUE_BIAS_MULTIPLY}, source = 'lineitem')`;
const lineItemRevenueFieldNoCurrencyConversion = `sumIf(${lineItemLookup} ${REVENUE_BIAS_MULTIPLY}, source = 'lineitem')`;

const apsRevenueField = `sumIf(${lineItemLookupWithCurrency} ${REVENUE_BIAS_MULTIPLY}, source = 'aps')`;
const apsRevenueFieldNoCurrencyConversion = `sumIf(${lineItemLookup} ${REVENUE_BIAS_MULTIPLY}, source = 'aps')`;

const directRevenueFieldLegacy = `sumIf(${lineItemLookupWithCurrency} ${REVENUE_BIAS_MULTIPLY}, source = 'lineitem' OR source = 'aps')`;
const directRevenueFieldNoCurrencyConversionLegacy = `sumIf(${lineItemLookup} ${REVENUE_BIAS_MULTIPLY}, source = 'lineitem' OR source = 'aps')`;

const lineItemRevenueFieldLegacy = `sumIf(${lineItemLookupWithCurrency} ${REVENUE_BIAS_MULTIPLY}, source = 'lineitem' OR source = 'aps')`;
const lineItemRevenueFieldNoCurrencyConversionLegacy = `sumIf(${lineItemLookup} ${REVENUE_BIAS_MULTIPLY}, source = 'lineitem' OR source = 'aps')`;

export type IRawMetric = {
    field?: string; // how to select this metric from the database
    fieldNoCurrencyConversion?: string; // how to select this metric from the DA database
    isRevenue?: boolean; // if revenue bias will multiply with this column
    isPrebid?: boolean; // is the revenue from prebid (used when applying revenue bias)
    isDynamicAllocation?: boolean; // is the revenue from da (used when applying revenue bias)
    isNativo?: boolean; // if the revenue is from nativo (used when applying revenue bias)
    isOutbrain?: boolean;
    isDirect?: boolean; // is the revenue from direct (used when applying revenue bias)
    config?: {
        [key: string]: number;
    };
    hideFromApi?: boolean;
    isTaboola?: boolean;
    isYahooGemini?: boolean;
    isGoogleAdsense?: boolean;
    isAyMediation?: boolean;
    isAmazonPublisherServices?: boolean; // is the revenue from amazon publisher services (used when applying revenue bias)
};

export const rawMetricsRequestReductionClientV2: Record<RawRequestReductionClientV2Metrics, IRawMetric> = {
    requests: {
        field: "count()",
    },
    waste: {
        field: "countIf(didBid = 0)",
    },
    bids: {
        field: "countIf(didBid = 1)",
    },
    wins: {
        field: "countIf(rendered = 1)",
    },
    saved_waste: {
        field: "countIf(didBid = 0 AND shouldBid = 0)",
    },
    untouched_bids: {
        field: "countIf(didBid = 1 AND shouldBid = 1)",
    },
    lost_bids: {
        field: "countIf(didBid = 1 AND shouldBid = 0)",
    },
    lost_wins: {
        field: "countIf(rendered = 1 AND shouldBid = 0)",
    },
    lost_bid_volume: {
        field: "sumIf(bid, shouldBid = 0)",
    },
    bid_volume: {
        field: "sum(bid)",
    },
    lost_won_volume: {
        field: "sumIf(bid, shouldBid = 0 AND rendered = 1)",
    },
    won_volume: {
        field: "sumIf(bid, rendered = 1)",
    },
    untouched_bid_volume: {
        field: "sumIf(bid, shouldBid = 1)",
    },
    left_waste: {
        field: "countIf(didBid = 0 AND shouldBid = 1)",
    },
    auctions: {
        field: "uniq(prebid_auctionId)",
    },
};

const rawMetricsRequestReductionServerSamplesPredicted = `(predicted > __threshold)`;
export const rawMetricsRequestReductionServerSamples: Record<RawRequestReductionServerSamplesMetrics, IRawMetric> = {
    request_reduction_server_samples_wins: {
        field: "countIf(did_win)",
    },
    request_reduction_server_samples_bids: {
        field: "countIf(did_bid)",
    },
    request_reduction_server_samples_predicts: {
        field: `countIf(${rawMetricsRequestReductionServerSamplesPredicted})`,
    },
    request_reduction_server_samples_bid_true_positive: {
        field: `countIf(${rawMetricsRequestReductionServerSamplesPredicted} AND did_bid)`,
    },
    request_reduction_server_samples_bid_true_negative: {
        field: `countIf(NOT ${rawMetricsRequestReductionServerSamplesPredicted} AND NOT did_bid)`,
    },
    request_reduction_server_samples_bid_false_positive: {
        field: `countIf(${rawMetricsRequestReductionServerSamplesPredicted} AND NOT did_bid)`,
    },
    request_reduction_server_samples_bid_false_negative: {
        field: `countIf(NOT ${rawMetricsRequestReductionServerSamplesPredicted} AND did_bid)`,
    },
    request_reduction_server_samples_win_true_positive: {
        field: `countIf(${rawMetricsRequestReductionServerSamplesPredicted} AND did_win)`,
    },
    request_reduction_server_samples_win_true_negative: {
        field: `countIf(NOT ${rawMetricsRequestReductionServerSamplesPredicted} AND NOT did_win)`,
    },
    request_reduction_server_samples_win_false_positive: {
        field: `countIf(${rawMetricsRequestReductionServerSamplesPredicted} AND NOT did_win)`,
    },
    request_reduction_server_samples_win_false_negative: {
        field: `countIf(NOT ${rawMetricsRequestReductionServerSamplesPredicted} AND did_win)`,
    },
    request_reduction_server_samples_missed_revenue: {
        field: `sumIf(cost_cpm, did_win AND NOT ${rawMetricsRequestReductionServerSamplesPredicted})`,
    },
    request_reduction_server_samples_requests: {
        field: "count()",
    },
    request_reduction_server_samples_win_revenue: {
        field: "sumIf(cost_cpm, did_win)",
    },
    request_reduction_server_samples_bid_revenue: {
        field: "sumIf(predict_samples.bid_cpm, predict_samples.did_bid)",
    },
};

export const rawMetricsErrorAnalytics: Record<RawErrorAnalyticsMetrics, IRawMetric> = {
    sessions: {
        field: "uniq(assumeNotNull(userAgent), assumeNotNull(latitude), assumeNotNull(longitude))",
    },
    errors: {
        field: "count()",
    },
};

export const rawMetricsWeb: Record<RawWebMetrics, IRawMetric> = {
    pageViews_ends: {
        field: "countIf(event_end = 1)",
    },
    sessions_ends: {
        field: "uniqIf(sessionId, event_end = 1)",
    },
    pageView_with_ads: {
        field: "countIf(pageView_impressionCount>0 AND event_end = 1)",
    },
    session_with_ads: {
        field: "uniqIf(sessionId, session_impressionCount>0 AND event_end = 1)",
    },
    scrollY: {
        field: "SUM(window_scrollY)",
    },
    impressions: {
        field: "SUM(pageView_impressionCount)",
    },
    errors: {
        field: "SUM(pageView_errorCount)",
    },
    sum_pageView_activeEngagementTime: {
        field: "sumIf(pageView_activeEngagementTime, event_end = 1)",
    },
    count_event_end: {
        field: "countIf(event_end = 1)",
    },
    count_low_engagement: {
        field: "countIf(pageView_activeEngagementTime < 10000 AND session_pageViewCount < 2 AND event_end = 1)",
    },
    pageViews: {
        field: "uniq(pageViewId)",
    },
    sessions: {
        field: "uniq(sessionId)",
    },
    session_starts: {
        field: "countIf(session_pageViewCount = 1)",
    },
    pageView_starts: {
        field: "countIf(pageview_isInitial = 1)",
    },
    vitals_lcp_measurable: {
        field: "countIf(vitals_lcp != -1 AND event_end = 1)",
    },
    vitals_lcp_sum: {
        field: "sumIf(vitals_lcp, vitals_lcp != -1 AND event_end = 1)/1000",
    },
    vitals_lcp_good: {
        field: "countIf(vitals_lcp != -1 AND event_end = 1 AND vitals_lcp <= 2500)",
    },
    vitals_lcp_medium: {
        field: "countIf(vitals_lcp != -1 AND event_end = 1 AND vitals_lcp > 2500 AND vitals_lcp <= 4000)",
    },
    vitals_lcp_bad: {
        field: "countIf(vitals_lcp != -1 AND event_end = 1 AND vitals_lcp > 4000)",
    },
    vitals_fid_measurable: {
        field: "countIf(vitals_fid != -1 AND event_end = 1)",
    },
    vitals_fid_sum: {
        field: "sumIf(vitals_fid, vitals_fid != -1 AND event_end = 1)",
    },
    vitals_fid_good: {
        field: "countIf(vitals_fid != -1 AND event_end = 1 AND vitals_fid <= 100)",
    },
    vitals_fid_medium: {
        field: "countIf(vitals_fid != -1 AND event_end = 1 AND vitals_fid > 100 AND vitals_fid <= 300)",
    },
    vitals_fid_bad: {
        field: "countIf(vitals_fid != -1 AND event_end = 1 AND vitals_fid > 300)",
    },
    vitals_cls_measurable: {
        field: "countIf(vitals_cls != -1 AND event_end = 1)",
    },
    vitals_cls_sum: {
        field: "sumIf(vitals_cls, vitals_cls != -1 AND event_end = 1)",
    },
    vitals_cls_good: {
        field: "countIf(vitals_cls != -1 AND event_end = 1 AND vitals_cls <= 0.1)",
    },
    vitals_cls_medium: {
        field: "countIf(vitals_cls != -1 AND event_end = 1 AND vitals_cls > 0.1 AND vitals_cls <= 0.25)",
    },
    vitals_cls_bad: {
        field: "countIf(vitals_cls != -1 AND event_end = 1 AND vitals_cls > 0.25)",
    },

    vitals_inp_measurable: {
        field: "countIf(vitals_inp != -1 AND event_end = 1)",
    },
    vitals_inp_sum: {
        field: "sumIf(vitals_inp, vitals_inp != -1 AND event_end = 1)",
    },
    vitals_inp_good: {
        field: "countIf(vitals_inp != -1 AND event_end = 1 AND vitals_inp <= 200)",
    },
    vitals_inp_medium: {
        field: "countIf(vitals_inp != -1 AND event_end = 1 AND vitals_inp > 200 AND vitals_inp <= 500)",
    },
    vitals_inp_bad: {
        field: "countIf(vitals_inp != -1 AND event_end = 1 AND vitals_inp > 500)",
    },

    vitals_ttfb_measurable: {
        field: "countIf(vitals_ttfb != -1 AND event_end = 1)",
    },
    vitals_ttfb_sum: {
        field: "sumIf(vitals_ttfb, vitals_ttfb != -1 AND event_end = 1)",
    },
    vitals_ttfb_good: {
        field: "countIf(vitals_ttfb != -1 AND event_end = 1 AND vitals_ttfb <= 800)",
    },
    vitals_ttfb_medium: {
        field: "countIf(vitals_ttfb != -1 AND event_end = 1 AND vitals_ttfb > 800 AND vitals_ttfb <= 1800)",
    },
    vitals_ttfb_bad: {
        field: "countIf(vitals_ttfb != -1 AND event_end = 1 AND vitals_ttfb > 1800)",
    },

    vitals_fcp_measurable: {
        field: "countIf(vitals_fcp != -1 AND event_end = 1)",
    },
    vitals_fcp_sum: {
        field: "sumIf(vitals_fcp, vitals_fcp != -1 AND event_end = 1)",
    },
    vitals_fcp_good: {
        field: "countIf(vitals_fcp != -1 AND event_end = 1 AND vitals_fcp <= 1800)",
    },
    vitals_fcp_medium: {
        field: "countIf(vitals_fcp != -1 AND event_end = 1 AND vitals_fcp > 1800 AND vitals_fcp <= 3000)",
    },
    vitals_fcp_bad: {
        field: "countIf(vitals_fcp != -1 AND event_end = 1 AND vitals_fcp > 3000)",
    },
    min_timestamp: {
        field: "toDateTime(min(timestamp), %%TIMEZONE%%)",
    },
    max_timestamp: {
        field: "toDateTime(max(timestamp), %%TIMEZONE%%)",
    },
    acquisition_cost: {
        field: `sumIf(acquisition_cost * ${convertToCurrencyCreator(currencyConversionFactorPV)(
            "acquisition_currency"
        )}, session_pageViewCount = 1)`,
        fieldNoCurrencyConversion: `sumIf(acquisition_cost, session_pageViewCount = 1)`,
    },
    acquisition_cost_measurable_session_starts: {
        field: `countIf(pageviews.acquisition_cost > 0 AND session_pageViewCount = 1)`,
    },
    nav_domain_lookup_time: {
        field: "SUM(nav_domain_lookup_time)",
    },
    nav_network_time: {
        field: "SUM(nav_network_time)",
    },
    nav_server_time: {
        field: "SUM(nav_server_time)",
    },
    nav_transfer_time: {
        field: "SUM(nav_transfer_time)",
    },
    nav_dom_processing_time: {
        field: "SUM(nav_dom_processing_time)",
    },
    nav_dom_content_loading_time: {
        field: "SUM(nav_dom_content_loading_time) * 1000",
    },
    nav_unload_event_time: {
        field: "SUM(nav_unload_event_time) * 1000",
    },
    nav_transfer_size: {
        field: "SUM(nav_transfer_size)",
    },
    nav_encoded_body_size: {
        field: "SUM(nav_encoded_body_size)",
    },
    nav_decoded_body_size: {
        field: "SUM(nav_decoded_body_size)",
    },
};
export const rawMetricsPrebid: Record<RawPrebidMetrics, IRawMetric> = {
    prebid_report_ivt_measurable_impressions: {
        field: "countIf(ivt_category > 0)",
    },
    prebid_report_givt_impressions: {
        field: "countIf(dictGetStringOrDefault('ivt', 'type', tuple(ivt_category), 'Unknown') = 'GIVT')",
    },
    prebid_report_sivt_impressions: {
        field: "countIf(dictGetStringOrDefault('ivt', 'type', tuple(ivt_category), 'Unknown') = 'SIVT')",
    },
    prebid_report_sessions: {
        field: "uniq(sessionId)",
    },
    prebid_report_pageViews: {
        field: "uniq(pageViewId)",
    },
    prebid_report_auctions: {
        field: "uniq(prebid_auctionId)",
    },
    prebid_report_requests: {
        field: "COUNT()",
    },
    prebid_report_bids: {
        field: "countIf(didBid)",
    },
    prebid_report_noBids: {
        field: "countIf(didBid = 0 AND didTimeout = 0)",
    },
    prebid_report_wins: {
        field: "countIf(if(isNull(rendered), 0, rendered))",
    },
    prebid_report_timeouts: {
        field: "countIf(didTimeout)",
    },
    prebid_report_bid_position: {
        field: "SUM(bidPosition + 1)",
    },
    prebid_report_bids_revenue: {
        field: `SUM(CASE WHEN didBid = 1 THEN bid ${REVENUE_BIAS_MULTIPLY} ELSE 0 END)`,
        isRevenue: true,
        isPrebid: true,
    },
    prebid_report_wins_revenue: {
        field: `SUM(CASE WHEN if(isNull(rendered), 0, rendered) = 1 THEN bid ${REVENUE_BIAS_MULTIPLY} ELSE 0 END)`,
        isRevenue: true,
        isPrebid: true,
    },
    prebid_report_timeToRespond: {
        field: "SUM(prebid_timeToRespond)",
    },
    prebid_report_setTargetingTime: {
        field: "SUM(setTargetingTime)",
    },
    prebid_report_renderedTime: {
        field: "SUM(renderedTime)",
    },
    prebid_report_floor_sum: {
        field: "SUM(ad_floor)",
    },
    prebid_report_floor_non_null: {
        field: "countIf(isNotNull(ad_floor))",
    },
    prebid_report_highest_bid: {
        field: "countIf(didBid AND if(isNull(bidPosition), -1, bidPosition) = 0)",
    },
};

const yieldRevenue = `multiIf(source='lineitem',${lineItemLookupWithCurrency}/1000/1000, source='prebid', toFloat64(events.prebid_highestBid/1000/1000/1000), source = 'nativo', toFloat64(${nativoRevenueCurrencyConverted(
    "events.revenue"
)}/1000/1000/1000/1000/1000), toFloat64(events.revenue/1000/1000/1000))`;

export const rawMetricsYield: Record<RawYieldMetrics, IRawMetric> = {
    blocked_impressions: {
        field: "countIf(adQuality_blockingType > 0 AND event_impression = 1)",
    },
    impressions_filled: {
        field: "countIf(ad_unfilled = 0 AND event_impression = 1)",
    },
    prebid_cached_count: {
        field: `countIf(prebid_cachedBid AND source = 'prebid' AND event_impression)`,
    },
    ad_unfilled_count: {
        field: "countIf(ad_unfilled = 1 AND event_impression = 1)",
    },
    prebid_user_id_providers_sum_uses: {
        field: "sumIf(length(prebid_userIds), event_impression = 1)",
    },
    prebid_user_id_providers_zero_usages: {
        field: "countIf(length(prebid_userIds) = 0 AND event_impression = 1)",
    },
    vitals_lcp_measurable: {
        field: "countIf(vitals_lcp != -1)",
    },
    vitals_lcp_sum: {
        field: "sumIf(vitals_lcp, vitals_lcp != -1)/1000",
    },
    vitals_lcp_good: {
        field: "countIf(vitals_lcp != -1 AND vitals_lcp <= 2500)",
    },
    vitals_lcp_medium: {
        field: "countIf(vitals_lcp != -1 AND vitals_lcp > 2500 AND vitals_lcp <= 4000)",
    },
    vitals_lcp_bad: {
        field: "countIf(vitals_lcp != -1 AND vitals_lcp > 4000)",
    },
    vitals_fid_measurable: {
        field: "countIf(vitals_fid != -1)",
    },
    vitals_fid_sum: {
        field: "sumIf(vitals_fid, vitals_fid != -1)",
    },
    vitals_fid_good: {
        field: "countIf(vitals_fid != -1 AND vitals_fid <= 100)",
    },
    vitals_fid_medium: {
        field: "countIf(vitals_fid != -1 AND vitals_fid > 100 AND vitals_fid <= 300)",
    },
    vitals_fid_bad: {
        field: "countIf(vitals_fid != -1 AND vitals_fid > 300)",
    },
    vitals_cls_measurable: {
        field: "countIf(vitals_cls != -1)",
    },
    vitals_cls_sum: {
        field: "sumIf(vitals_cls, vitals_cls != -1)",
    },
    vitals_cls_good: {
        field: "countIf(vitals_cls != -1 AND vitals_cls <= 0.1)",
    },
    vitals_cls_medium: {
        field: "countIf(vitals_cls != -1 AND vitals_cls > 0.1 AND vitals_cls <= 0.25)",
    },
    vitals_cls_bad: {
        field: "countIf(vitals_cls != -1 AND vitals_cls > 0.25)",
    },
    vitals_inp_measurable: {
        field: "countIf(vitals_inp != -1)",
    },
    vitals_inp_sum: {
        field: "sumIf(vitals_inp, vitals_inp != -1)",
    },
    vitals_inp_good: {
        field: "countIf(vitals_inp != -1 AND vitals_inp <= 200)",
    },
    vitals_inp_medium: {
        field: "countIf(vitals_inp != -1 AND vitals_inp > 200 AND vitals_inp <= 500)",
    },
    vitals_inp_bad: {
        field: "countIf(vitals_inp != -1 AND vitals_inp > 500)",
    },

    vitals_ttfb_measurable: {
        field: "countIf(vitals_ttfb != -1)",
    },
    vitals_ttfb_sum: {
        field: "sumIf(vitals_ttfb, vitals_ttfb != -1)",
    },
    vitals_ttfb_good: {
        field: "countIf(vitals_ttfb != -1 AND vitals_ttfb <= 800)",
    },
    vitals_ttfb_medium: {
        field: "countIf(vitals_ttfb != -1 AND vitals_ttfb > 800 AND vitals_ttfb <= 1800)",
    },
    vitals_ttfb_bad: {
        field: "countIf(vitals_ttfb != -1 AND vitals_ttfb > 1800)",
    },

    vitals_fcp_measurable: {
        field: "countIf(vitals_fcp != -1)",
    },
    vitals_fcp_sum: {
        field: "sumIf(vitals_fcp, vitals_fcp != -1)",
    },
    vitals_fcp_good: {
        field: "countIf(vitals_fcp != -1 AND vitals_fcp <= 1800)",
    },
    vitals_fcp_medium: {
        field: "countIf(vitals_fcp != -1 AND vitals_fcp > 1800 AND vitals_fcp <= 3000)",
    },
    vitals_fcp_bad: {
        field: "countIf(vitals_fcp != -1 AND vitals_fcp > 3000)",
    },

    min_timestamp: {
        field: "toDateTime(min(timestamp), %%TIMEZONE%%)",
    },
    max_timestamp: {
        field: "toDateTime(max(timestamp), %%TIMEZONE%%)",
    },
    ivt_measurable_impressions: {
        field: "countIf(ivt_category > 0 AND event_impression)",
    },
    givt_impressions: {
        field: "countIf(dictGetStringOrDefault('ivt', 'type', tuple(ivt_category), 'Unknown') = 'GIVT' AND event_impression)",
    },
    sivt_impressions: {
        field: "countIf(dictGetStringOrDefault('ivt', 'type', tuple(ivt_category), 'Unknown') = 'SIVT' AND event_impression)",
    },
    impressions: {
        field: "countIf(event_impression)",
    },
    impressions_unfilled: {
        field: "countIf(ad_unfilled AND event_impression)",
    },
    impressions_lineitem_house: {
        field: "countIf(event_impression AND dictGetStringOrDefault('lineItem', 'lineItemType', toUInt64(dfp_lineItemId), 'Unknown') = 'HOUSE')",
    },
    pageViews: {
        field: "uniqIf(pageViewId, event_impression)",
    },
    pageViewsExact: {
        field: "uniqExact(pageViewId)",
        hideFromApi: true,
    },
    sessions: {
        field: "uniqIf(sessionId, event_impression)",
    },
    sessions_with_bounced_clicks: {
        // following clicksBounced: user returned to the site within 10 seconds -> click bounced / miss click
        field: `uniqIf(sessionId, event_clickBounced != 0 AND event_clickBounced < ${clickBounceThreshold})`,
        config: {
            clickBounceThreshold,
        },
    },
    session_starts: {
        field: "countIf(session_impressionCount = 1 AND event_impression)",
    },
    pageView_starts: {
        field: "countIf(pageView_impressionCount = 1 AND event_impression)",
    },
    first_five_indicator: {
        field: "sumIf(prebid_highestBid, session_impressionCount < 6 AND event_impression)",
    },
    viewable: {
        field: "countIf(event_viewed > 0 AND viewed_measurable)",
    },
    viewable_measurable_impressions: {
        field: "countIf(event_impression AND viewed_measurable)",
    },
    clicks: {
        field: "countIf(event_clicked > 0 AND ad_unfilled = 0)",
    },
    clicksBounced: {
        // user returned to the site within 10 seconds -> click bounced / miss click
        field: `countIf(event_clickBounced != 0 AND event_clickBounced < ${clickBounceThreshold})`,
        config: {
            clickBounceThreshold,
        },
    },
    clicksReturned: {
        // user returned to the site
        field: "countIf(event_clickBounced > 0)",
    },
    prebid_revenue: {
        field: `sumIf(prebid_highestBid ${REVENUE_BIAS_MULTIPLY}, event_impression)`,
        isRevenue: true,
        isPrebid: true,
    },
    prebid_won_impressions: {
        field: "countIf(event_impression AND source = 'prebid')",
    },
    prebid_won_revenue: {
        field: `sumIf(prebid_highestBid ${REVENUE_BIAS_MULTIPLY}, event_impression AND source = 'prebid')`,
        isRevenue: true,
        isPrebid: true,
    },
    prebid_lost_impressions: {
        field: "countIf(event_impression AND source != 'prebid')",
    },
    prebid_lost_revenue: {
        field: `sumIf(prebid_highestBid ${REVENUE_BIAS_MULTIPLY}, event_impression AND source != 'prebid')`,
        isRevenue: true,
        isPrebid: true,
    },
    prebid_incremental_value: {
        field: `sumIf((assumeNotNull(prebid_highestBid) - assumeNotNull(prebid_secondHighestBid)) ${REVENUE_BIAS_MULTIPLY}, event_impression AND source = 'prebid')`,
        isRevenue: true,
        isPrebid: true,
    },
    nativo_revenue: {
        field: `sumIf(${nativoRevenueCurrencyConverted(
            "revenue"
        )} ${REVENUE_BIAS_MULTIPLY}, source = 'nativo')/1000/1000/1000`,
        fieldNoCurrencyConversion: `sumIf(revenue, source = 'nativo')/1000/1000/1000`,
        isRevenue: true,
        isNativo: true,
    },
    nativo_impressions: {
        field: "countIf(event_impression AND source = 'nativo')",
    },
    //*
    outbrain_revenue: {
        field: `sumIf(revenue ${REVENUE_BIAS_MULTIPLY} * ${currencyConversionFactorE(
            "'USD'"
        )}, source = 'outbrain')/1000`,
        fieldNoCurrencyConversion: `sumIf(revenue ${REVENUE_BIAS_MULTIPLY}, source = 'outbrain')/1000`,
        isRevenue: true,
        isOutbrain: true,
    },
    outbrain_impressions: {
        field: "countIf(event_impression AND source = 'outbrain')",
    },
    taboola_revenue: {
        field: `sumIf(revenue ${REVENUE_BIAS_MULTIPLY}, source = 'taboola')/1000`,
        isRevenue: true,
        isTaboola: true,
    },
    taboola_impressions: {
        field: "countIf(event_impression AND source = 'taboola')",
    },
    yahooGemini_revenue: {
        field: `sumIf(revenue ${REVENUE_BIAS_MULTIPLY}, source = 'yahooGemini')/1000`,
        isRevenue: true,
        isYahooGemini: true,
    },
    yahooGemini_impressions: {
        field: "countIf(event_impression AND source = 'yahooGemini')",
    },
    aps_revenue: {
        field: apsRevenueField,
        fieldNoCurrencyConversion: apsRevenueFieldNoCurrencyConversion,
        isRevenue: true,
        isAmazonPublisherServices: true,
    },
    aps_impressions: {
        field: "countIf(event_impression AND source = 'aps')",
    },
    adSense_revenue: {
        field: `sumIf(revenue ${REVENUE_BIAS_MULTIPLY}, source = 'adSense')/1000`,
        isRevenue: true,
        isGoogleAdsense: true,
    },
    adSense_impressions: {
        field: "countIf(event_impression AND source = 'adSense')",
    },
    ayMediation_revenue: {
        field: `sumIf(revenue ${REVENUE_BIAS_MULTIPLY}, source = 'ayMediation_cpa')/1000`,
        isRevenue: true,
        isAyMediation: true,
    },
    ayMediation_impressions: {
        field: "countIf(event_impression AND source = 'ayMediation_cpa')",
    },
    dynamicAllocation_impressions: {
        field: "countIf(event_impression AND source = 'dynamicAllocation')",
    },
    dynamicAllocation_predicted_impressions: {
        field: "countIf(event_impression AND source = 'dynamicAllocation' AND isNotNull(da_predicted))",
    },
    dynamicAllocation_revenue: {
        field: `sumIf(revenue ${REVENUE_BIAS_MULTIPLY}, source = 'dynamicAllocation')/ 1000`,
        isRevenue: true,
        isDynamicAllocation: true,
    },
    dynamicAllocation_revenue_sum_of_squares: {
        field: `SUM(if(source = 'dynamicAllocation', if(isNotNull(revenue), pow(revenue / 1000, 2), 0), 0) ${REVENUE_BIAS_MULTIPLY})`,
        isRevenue: true,
        isDynamicAllocation: true,
        hideFromApi: true,
    },
    dynamicAllocation_revenue_with_forecast: {
        field: `sumIf(toFloat64(if( %%MAX_DA_TIMESTAMP_PER_ENTITY%% > timestamp, revenue, toInt64(da_predicted_server))) ${REVENUE_BIAS_MULTIPLY}, source = 'dynamicAllocation') / 1000`,
        isRevenue: true,
        isDynamicAllocation: true,
    },
    dynamicAllocation_revenue_with_forecast_client: {
        field: `sumIf(toFloat64(if( %%MAX_DA_TIMESTAMP_PER_ENTITY%% > timestamp, revenue, toInt64(da_predicted) )) ${REVENUE_BIAS_MULTIPLY},source = 'dynamicAllocation') / 1000`,
        isRevenue: true,
        isDynamicAllocation: true,
    },
    dynamicAllocation_predicted_revenue: {
        field: `sumIf(da_predicted ${REVENUE_BIAS_MULTIPLY}, event_impression AND source = 'dynamicAllocation')`,
        isRevenue: true,
        isDynamicAllocation: true,
    },
    dynamicAllocation_predicted_revenue_residual: {
        // from https://en.wikipedia.org/wiki/Coefficient_of_determination

        field: `sumIf(
            pow(
                revenue / 1000
                - toFloat64(da_predicted) / 1000,
            2) ${REVENUE_BIAS_MULTIPLY},
            %%MAX_DA_TIMESTAMP_PER_ENTITY%% > timestamp AND event_impression AND (source = 'dynamicAllocation')
        )`,
        isRevenue: true,
        isDynamicAllocation: true,
        hideFromApi: true,
    },
    dynamicAllocation_predicted_revenue_abs_difference: {
        field: `sumIf(
            abs(
                revenue / 1000
                - toFloat64(da_predicted) / 1000
            ) ${REVENUE_BIAS_MULTIPLY},
            %%MAX_DA_TIMESTAMP_PER_ENTITY%% > timestamp AND event_impression AND (source = 'dynamicAllocation')
        )`,
        isRevenue: true,
        isDynamicAllocation: true,
        hideFromApi: true,
    },
    dynamicAllocation_predicted_revenue_server: {
        field: `sumIf(da_predicted_server ${REVENUE_BIAS_MULTIPLY}, event_impression AND source = 'dynamicAllocation')`,
        isRevenue: true,
        isDynamicAllocation: true,
    },
    dynamicAllocation_predicted_revenue_server_residual: {
        // from https://en.wikipedia.org/wiki/Coefficient_of_determination

        field: `sumIf(
            pow(
                revenue / 1000
                - toFloat64(da_predicted_server) / 1000,
            2) ${REVENUE_BIAS_MULTIPLY},
            %%MAX_DA_TIMESTAMP_PER_ENTITY%% > timestamp AND event_impression AND (source = 'dynamicAllocation')
        )`,
        isRevenue: true,
        isDynamicAllocation: true,
        hideFromApi: true,
    },
    dynamicAllocation_predicted_revenue_server_abs_difference: {
        field: `sumIf(
            abs(
                revenue / 1000
                - toFloat64(da_predicted_server) / 1000
            ) ${REVENUE_BIAS_MULTIPLY},
            %%MAX_DA_TIMESTAMP_PER_ENTITY%% > timestamp AND event_impression AND (source = 'dynamicAllocation')
        )`,
        isRevenue: true,
        isDynamicAllocation: true,
        hideFromApi: true,
    },
    dynamicAllocation_confirmed_clicks: {
        field: "countIf(event_impression AND dfp_confirmedClick)",
    },
    direct_impressions: {
        field: "countIf(event_impression AND source = 'lineitem')",
    },
    direct_revenue: {
        // to convert currency, we must multiply the value by (the rate of the "to" currency / the rate of the "from" currency)
        // the "to" currency is hardcoded to EUR and the "from" currency if fetched from the lineItem dictionary
        field: directRevenueField,
        fieldNoCurrencyConversion: directRevenueFieldNoCurrencyConversion,
        isRevenue: true,
        isDirect: true,
    },
    direct_impressions_legacy: {
        field: "countIf(event_impression AND (source = 'lineitem' OR source = 'aps'))",
        hideFromApi: true,
    },
    direct_revenue_legacy: {
        // to convert currency, we must multiply the value by (the rate of the "to" currency / the rate of the "from" currency)
        // the "to" currency is hardcoded to EUR and the "from" currency if fetched from the lineItem dictionary
        field: directRevenueFieldLegacy,
        fieldNoCurrencyConversion: directRevenueFieldNoCurrencyConversionLegacy,
        isRevenue: true,
        isDirect: true,
        hideFromApi: true,
    },
    lineItem_revenue_legacy: {
        // to convert currency, we must multiply the value by (the rate of the "to" currency / the rate of the "from" currency)
        // the "to" currency is hardcoded to EUR and the "from" currency if fetched from the lineItem dictionary
        field: lineItemRevenueFieldLegacy,
        fieldNoCurrencyConversion: lineItemRevenueFieldNoCurrencyConversionLegacy,
        isRevenue: true,
        isDirect: true,
        hideFromApi: true,
    },
    lineItem_revenue: {
        // to convert currency, we must multiply the value by (the rate of the "to" currency / the rate of the "from" currency)
        // the "to" currency is hardcoded to EUR and the "from" currency if fetched from the lineItem dictionary
        field: lineItemRevenueField,
        fieldNoCurrencyConversion: lineItemRevenueFieldNoCurrencyConversion,
        isRevenue: true,
        isDirect: true,
    },
    acquisition_cost: {
        field: `sumIf(acquisition_cost * ${convertToCurrency(
            "acquisition_currency"
        )}, session_impressionCount = 1 AND event_impression)`,
        fieldNoCurrencyConversion: `sumIf(acquisition_cost, session_impressionCount = 1 AND event_impression)`,
    },
    acquisition_cost_measurable_session_starts: {
        field: `countIf(events.acquisition_cost > 0 AND session_impressionCount = 1 AND event_impression)`,
    },

    // floor
    floor: {
        field: "sumIf(ad_floor, event_impression)",
    },
    floor_empirical_revenue: {
        field: `SUM(assumeNotNull(if((toFloat64(ad_floor/1000/1000/1000) > ${yieldRevenue}) OR NOT event_impression, 0.0, least(toFloat64(ad_floor/1000/1000/1000), ${yieldRevenue}))))`,
    },
    floor_measurable_impressions: {
        field: "countIf(event_impression AND isNotNull(ad_floor))",
    },

    // optimization/bias
    bias: {
        hideFromApi: true,
    },
    _count: {
        hideFromApi: true,
    },
    prebid_lost_revenue_hb: {
        hideFromApi: true,
    },

    uniqueExternalId: {
        field: "uniq(externalId)",
    },
};

export const rawMetricsDiscrepancyRevenue: Record<DiscrepancyRevenueReportRawMetrics, IRawMetric> = {
    actual_revenue: {
        field: "actual_revenue",
        isRevenue: true,
        hideFromApi: true,
    },
    assertive_revenue: {
        field: "assertive_revenue",
        isRevenue: true,
        hideFromApi: true,
    },
    assertive_impressions: {
        field: "assertive_impressions",
        hideFromApi: true,
    },
    actual_impressions: {
        field: "actual_impressions",
        hideFromApi: true,
    },
};

export const rawMetricsFinancialReport: Record<FinancialReportMetrics, IRawMetric> = {
    actual_revenue: {
        field: "actual_revenue",
        isRevenue: true,
        hideFromApi: true,
    },
    actual_impressions: {
        field: "actual_impressions",
        hideFromApi: true,
    },
    actual_revenue_ssp_currency: {
        field: "actual_revenue_ssp_currency",
        isRevenue: true,
        hideFromApi: true,
    },
};

export type AnyRawMetricMap = Record<string, IRawMetric>;
const EMPTY: AnyRawMetricMap = {};
export const RAW_METRICS_BY_PLACEMENT: Record<ReportPlacementKeyType, AnyRawMetricMap> = {
    web: rawMetricsWeb,
    yield: rawMetricsYield,
    flooring: rawMetricsYield,
    prebid: rawMetricsPrebid,
    financial: rawMetricsFinancialReport,
    discrepancy_revenue: rawMetricsDiscrepancyRevenue,
    request_reduction_server_samples_predict: rawMetricsRequestReductionServerSamples,
    request_reduction_server_samples_profile: rawMetricsRequestReductionServerSamples,
    buy_campaign: EMPTY,
    buy_adset: EMPTY,
    buy_ad: EMPTY,
    industry: EMPTY,
    ad_revenue: EMPTY,
    session_revenue: EMPTY,
    session_time: EMPTY,
    site: EMPTY,
    spent_by_section: EMPTY,
    request_reduction_server_latency: EMPTY,
    time_series_forecast: EMPTY,
    view_dispersion: EMPTY,
    error_log: EMPTY,
    segments: EMPTY,
    error_analytics: rawMetricsErrorAnalytics,
    request_reduction_client_v2: rawMetricsRequestReductionClientV2,
};
